export default {
    WELCOME_TEXT: "Tere, {0}!",
    OUT_OF_OFFICE: "{0} on kontorist eemal.",
    DASHBOARD_TEXT: "Meie portaal on pidevas arengus. Hoiame Sind kursis, kui lisame uut funktsionaalsust!",
    PRIMARY_CONTACT: "Teie kontakt",
    READ_MORE: "Loe edasi",
    LESS: "Vaata vähem",
    CLOSE: "Sulge",
    OPEN: "Ava",
    COPY: "Kopeeri",
    SELECTED: "valitud",
    CO2_EMISSIONS:"jalajälg",
    CO2_EMISSIONS_TOOLTIP: "Co2 jalajälg arvutatakse peale saadetise kohaletoimetamist, arvestades marsruudi eripära.",
    CREATED_ON: 'loodud',
    REFRESH_DATA: 'Värskenda vaadet',
    TRY_FULL_SCREEN: 'Proovige täisekraani vaadet, et saada veelgi parem ülevaade',
    DATEPICKER_PLACEHOLDER: "pp.kk.aaaa",
    NOT_FOUND_HEADING: "Oh ei!",
    NOT_FOUND_DESC: "Lehte mida sa otsid pole olemas või ",
    NOT_FOUND_DESC_2: "sul ei ole õigust seda näha.",
    NOT_FOUND_ERROR_CODE: "Veakood: 404",
    FILTER_RESULT: "Otsingu tulemus",
    FILTER_SEARCH_RESULT: "Otsingutulemus ",
    ERROR_OUR_BUG: "Meie viga! Töötame juba probleemi kallal ja varsti saab kõik korda!",
    GO_TO_HOMEPAGE: "Mine saadetiste lehele",
    GO_BACK: 'Tagasi',
    SEARCH_INSERT_KEYWORD: 'Sisesta otsingu märksõna',
    FORGOT_PASSWORD: 'Unustasid oma salasõna?',
    SEND_EMAIL: 'Saada email',
    OPERATOR_SEEN: 'Operaator on näinud',
    RESET_PASSWORD_BOX_TITLE: "Muuda parool",
    RESET_PASSWORD_BTN: "Muuda",
    FORGOT_PASSWORD_BOX_TITLE: "Unustasite parooli?",
    FORGOT_PASSWORD_BOX_SUCCESS: "Täname! Kui kasutajanimi eksisteerib meie süsteemis, peaksite varsti e-posti teel saama lingi parooli lähtestamiseks.",
    IMPORT_ADDRESSES: 'Impordi aadressid Exceli failist',
    LOGIN_BOX_TITLE: 'Kliendiportaal',
    LOGIN_FAILED: "Automaatne sisselogimine ebaõnnestus",
    LOGIN_BOX_DESC: 'Oma saadetiste ja nende olekute nägemiseks logi sisse meie iseteenindusse.',
    INVITATION_BOX_TITLE: 'Loo uus kasutaja',
    TERMS_OF_SERVICE: 'Kasutustingimused',
    USERNAME: 'Kasutajanimi',
    PASSWORD: 'Parool',
    PASSWORD_AGAIN: 'Parool uuesti',
    SIGN_IN: 'Logi sisse',
    PASSWORDS_DO_NOT_MATCH: 'Paroolid ei kattu!',
    USER_NOT_CREATED: 'Kasutaja loomine ebaõnnestus!',
    CREATE_USER_PASSWORD_ERROR: "Palun kontrolli paroolis nõutud tingimusi. (Nõutav on vähemalt üks erimärk, number, väike-/suurtäht.)",
    CREATE_USER: 'Loo kasutaja',
    PASSWORD_HINT: 'Parooli minimaalne pikkus on 8 tähte. Nõutav on vähemalt üks erimärk, number, väike-/suurtäht.',
    FAILED_TO_LOG_IN: 'Sisselogimine ebaõnnestus',
    FAILED_TO_LOG_IN_BLOCKED: 'Teie konto on blokeeritud. Palun kirjutage aadressil customer@laasone.com blokeeringu eemaldamiseks',
    MY_PROFILE: 'Minu profiil',
    THIS_IS_DEMO_ENVIRONMENT: 'See on demo keskkond. Siia sisestatud andmeid ei töödelda.',
    INVOICES: 'Arved',
    WAREHOUSE: 'Laovaade',
    ALL: 'Kõik',
    INCOMING: 'Saabuvad',
    OUTGOING: 'Väljuvad',
    YESTERDAY: 'Eile ({0})',
    TODAY: 'Täna ({0})',
    TOMORROW: 'Homme ({0})',
    ENTER_FULLSCREEN: 'Täisekraani vaade',
    LABEL_NOT_PRINTED: 'Sildid printimata',
    LABEL_PRINTED: 'Sildid prinditud',
    CMR_NOT_PRINTED: 'CMR printimata',
    CMR_PRINTED: 'CMR prinditud',
    INVOICES_EXTRA: 'arvet',
    ADDRESS_BOOK: 'Aadressiraamat',
    RESULTS_FROM_ADDRESS_BOOK: 'Tulemused aadressiraamatust:',
    PRICE_REQUESTS: 'Hinnapäringud',
    RESULTS_FROM_GOOGLE_MAPS: 'Tulemused Google\'i kaardilt:',
    COMPANY_PROFILE: 'Ettevõtte profiil',
    COMPANY_DETAILS: 'Ettevõtte info',
    LOG_OUT: 'Logi välja',
    SHOW_ONLY_SHIPMENTS_CREATED_BY_ME: 'Näita ainult minu loodud saadetisi',
    SHOW_ONLY_SHIPMENTS_CREATED_BY_ME_TOOLTIP: 'Kui see on märgitud, siis näidatakse nimekirjas ainult sinu loodud saadetisi. Kui sa aga selle märke maha võtad, siis näed kõiki oma ettevõtte saadetisi.',
    SHOW_ONLY_PRICE_REQUESTS_CREATED_BY_ME: 'Näita ainult minu loodud hinnapäringuid',
    SHOW_ONLY_PRICE_REQUESTS_CREATED_BY_ME_TOOLTIP: 'Kui see on märgitud, siis näidatakse nimekirjas ainult sinu loodud hinnapäringuid. Kui sa aga selle märke maha võtad, siis näed kõiki oma ettevõtte hinnapäringuid.',
    OPEN_FILTER: 'Ava filter',
    SORT_BY: 'Sorteeri',
    SELECT: 'Vali',
    SHIPMENT_NUMBER: 'Saadetise nr',
    REFERENCE_NO: 'Viitenumber',
    AMOUNT: 'Kogus',
    CARRIER: 'Vedaja',
    PICKUP: 'Korje',
    PICKUP_DATE: 'Korje aeg',
    PICKUP_ADDRESS: 'Aadress',
    PICKUP_CONTACT: 'Korje kontakt',
    DELIVERY_CONTACT: 'Tarne kontakt',
    DELIVERY: 'Tarne',
    DELIVERY_DATE: 'Tarne aeg',
    DELIVERY_ADDRESS: 'Aadress',
    STATUS: 'Staatus',
    DATE: 'Kuupäev',
    SHOW: 'Näita',
    UPLOADED: 'üles laetud',
    CLOSE_ALERT: 'Sulge teade',
    LABELS_SENT_DETAILS: 'Sildid saadetud',
    LOCK_FILE_TOOLTIP: 'Lukusta fail, et teised kasutajad ei saaks seda allaladida ega kustutada',
    UNLOCK_FILE_TOOLTIP: 'Eemalda faili lukustus, et teised kasutajad saaksid ka seda faili allaladida või kustutada',
    LOCKING_FILE_CONFIRM_TEXT: 'Oled kindel, et soovid muuta "{0}" lukustust?',
    FILE_IS_LOCKED: 'Faili lukustas ',
    SHIPMENT_SORT_SHIPMENT_NO_DSC: 'Saadetise nr: kahanev',
    SHIPMENT_SORT_SHIPMENT_NO_ASC: 'Saadetise nr: kasvav',
    SHIPMENT_SORT_PICKUP_DATE_DSC: 'Korje kuupäev: kahanev',
    SHIPMENT_SORT_PICKUP_DATE_ASC: 'Korje kuupäev: kasvav',
    SHIPMENT_SORT_DELIVERY_DATE_DSC: 'Tarne kuupäev: kahanev',
    SHIPMENT_SORT_DELIVERY_DATE_ASC: 'Tarne kuupäev: kasvav',
    TEMPLATE_SORT_TEMPLATE_NAME_ASC: 'Malli nimi: kasvav',
    TEMPLATE_SORT_TEMPLATE_NAME_DESC: 'Malli nimi: kahanev',
    TEMPLATE_SORT_TEMPLATE_PICKUP_ASC: 'Korje ettevõte: kasvav',
    TEMPLATE_SORT_TEMPLATE_PICKUP_DESC: 'Korje ettevõte: kahanev',
    TEMPLATE_SORT_TEMPLATE_DELIVERY_ASC: 'Tarne ettevõte: kasvav',
    TEMPLATE_SORT_TEMPLATE_DELIVERY_DESC: 'Tarne ettevõte: kahanev',
    ADDRESSBOOK_SORT_NAME_ASC: 'Aadressi nimi: kasvav',
    ADDRESSBOOK_SORT_NAME_DESC: 'Aadressi nimi: kahanev',
    ADDRESSBOOK_SORT_COMPANY_ASC: 'Ettevõtte nimi: kasvav',
    ADDRESSBOOK_SORT_COMPANY_DESC: 'Ettevõtte nimi: kahanev',
    ADDRESSBOOK_SORT_COUNTRY_ASC: 'Riik: kasvav',
    ADDRESSBOOK_SORT_COUNTRY_DESC: 'Riik: kahanev',
    PRICE_REQUEST_SORT_SHIPMENT_NO_DSC: 'Hinnapäringu nr: kahanev',
    PRICE_REQUEST_SORT_SHIPMENT_NO_ASC: 'Hinnapäringu nr: kasvav',
    SHIPMENT_FILTER_SHIPMENT_NO: 'Saadetise nr',
    SHIPMENT_FILTER_REFERENCE_NO: 'Viitenumber',
    SHIPMENT_FILTER_PICKUP_FROM: 'Lähteaadressi riik',
    SHIPMENT_FILTER_PICKUP_DATE: 'Korje kuupäev',
    SHIPMENT_FILTER_DELIVERY_TO: 'Sihtaadressi riik',
    SHIPMENT_FILTER_DELIVERY_DATE: 'Tarne kuupäev',
    SHIPMENT_FILTER_STATUS: 'Staatus',
    SEARCH: 'Otsi',
    SELECT_COUNTRY: 'Vali riik',
    DOWNLOAD_POD: 'Laadi alla tarnetõend (POD)',
    COPY_TRACKING_LINK: 'Kopeeri jälgimise link',
    TRACKING_LINK_COPIED: 'Jälgimise link kopeeritud',
    SEEN_BY_THE_OPERATOR: 'Operaator on näinud',
    ON: 'SEES',
    OFF: 'VÄLJAS',
    PICKUP_AND_DELIVERY_DATES: 'Laadimisajad',
    EARLIEST_PICKUP: 'Varasem korje',
    LATEST_PICKUP: 'Hilisem korje',
    EARLIEST_DELIVERY: 'Varasem tarne',
    LATEST_DELIVERY: 'Hilisem tarne',
    FIXED_PICKUP: 'Fikseeritud korje',
    FIXED_DELIVERY: 'Fikseeritud tarne',
    ITEMS_IN_THE_SHIPMENT: 'Saadetise kaubad',
    ITEMS_ADDED_PRICE_REQUEST: 'Kaubad hinnapäringus',
    COMMENTS_REGARDING_THE_ITEMS: 'Kommentaarid kaupade kohta',
    PICKUP_FROM: 'Korje',
    DELIVERY_TO: 'Tarne',
    LENGTH: 'Pikkus',
    HEIGHT_TOOLTIP: 'Pakendi pikkus on sellel pakendi tüübil standardiseeritud',
    WIDTH: 'Laius',
    WIDTH_TOOLTIP: 'Pakendi laius on sellel pakendi tüübil standardiseeritud',
    HEIGHT: 'Kõrgus',
    VOLUME: 'Maht',
    WEIGHT_PC: 'Kaal/tk',
    TOTAL_WEIGHT: 'Kogukaal',
    LDM: 'LDM',
    PRODUCT_DESCRIPTION: 'Kauba kirjeldus',
    DESCRIPTION: 'Kirjeldus',
    LOCATION: 'Asukoht',
    UN_CODE: 'UN kood',
    PG: 'Pakendigrupp',
    CLASS: 'Klass',
    COLD_FROZEN: 'Jahutatud/külmutatud',
    STACKABLE: 'Virnastatav',
    DELICATE: 'Õrn kaup',
    PROPER_SHIPPING_NAME: 'Ohtliku veose tunnusnimetus',
    YOU_ARE_LOGGED_OUT: 'Oled edukalt välja logitud',
    SIDEBAR_DASHBOARD: 'Töölaud',
    SIDEBAR_SHIPMENTS: 'Saadetised',
    SIDEBAR_PRICE_REQUESTS: 'Hinnapäringud',
    SIDEBAR_USERS: 'Kasutajad',
    ADD_DOCUMENTS_TITLE: 'Lisa dokumendid',
    DASHBOARD: {
        LAST_MESSAGES: 'Viimased sõnumid',
        PRICE_REQUEST_SECTION: 'Teie tähelepanu vajavad hinnapäringud',
        SHIPMENTS_SECTION: 'Teie tähelepanu vajavad saadetised',
        NOT_CONFIRMED_PR: 'Kinnitamata ({0})',
        NOT_ORDERED_PR: 'Tellimus vormistamata ({0})',
        CURRENT_MONTH_STATISTICS: 'Jooksva kuu kohale toimetatud saadetised arvudes',
        INCOMING: 'Saabuvad',
        OUTGOING: 'Väljuvad',
        TOTAL_WEIGHT: 'Kaal',
        VOLUME: 'maht',
        IN_COUNTRY_PERCENT: '{0}% väljuvatest saadetistest olid suunal {1}-{1}',
        SENDING_COST: '{0} € oli saadetiste transpordikulu',
    },
    USERS: {
        ADD_USER: 'Kutsu uus kasutaja',
        EDIT_USER_TITLE: 'Muuda kasutaja andmeid',
        FIRST_NAME: 'Eesnimi',
        LAST_NAME: 'Perekonnanimi',
        LIMITED_ACCESS: 'Määra kasutajale kohandatud juurdepääs',
        LIMITED_ACCESS_TOOLTIP:'Kui kasutajaõigusi pole seadistatud, on kasutajal ligipääs kõigile portaali andmetele.',
        USERNAME: 'Kasutajanimi / e-post',
        PRIMARY_CONTACT: 'LaasOne primary contact',
        BTN_SAVE_BUTTON: "Salvesta",
        RIGHTS: {
            NO_ACCESS: 'Ligipääs puudub',
            VIEW: 'Ainult vaatamine',
            ADD_EDIT: 'Lisamine ja muutmine',
        },
        INVITED: 'Ootel',
        DELETE_SUCCESS: 'Kasutaja kustutatud',
        DELETE_CONFIRMATION: 'Kas sa oled kindel, et soovid kasutaja kustutada?',
        BTN_DELETE: "Kustuta",
        TEXT_USER_INVITED: "Kasutajale kutse saadetud",
        TEXT_USER_SAVED: "Kasutaja andmed salvestatud",
        LIMITED_ACCESS_LABEL: "Piiratud ligipääs",
        SHOW_SHIPMENT_PRICE: "Näita saadetiste hinda",
        INVITATION_URL_COPIED: "Link kasutaja registreerimiseks on kopeeritud! Jaga seda linki uue kasutajaga, et ta saaks ise registreerimise lõpetada.",
        ACCESS_ALL_OFFICES:'Ligipääs kõigi asukohtade saadetistele',
        ACCESS_ALL_OFFICES_TOOLTIP: 'Vaikimisi on kasutajal juurdepääs kõigi asukohtade teabele. Kohandamiseks valige palun konkreetsed asukohad.',
        CHOOSE_OFFICES:'Vali asukohad, millele lubada ligipääs',
        WAREHOUSE_WORKER: 'Kasutajal on juurdepääs ainult lao vaatele.',
        WAREHOUSE_WORKER_TOOLTIP: 'Piirab kasutaja juurdepääsu ainult laoga seotud teabe vaatamisele.',
    },
    USERNAME_EXISTS: "Kasutajanimi on juba kasutusel",
    REPLAY: 'Vasta',
    DROPZONE: {
        DRAG_AND_DROP: 'Lohista failid siia või',
        CHOOSE_FROM_COMPUTER: 'vali failid oma arvutist',
        SUPPORTED_FILES: 'Toetatud failitüübid: PDF, PNG, JPG, JPEG',
        SUPPORTED_FILES_XLS: 'Toetatud failitüübid: XLSX',
        ERROR: 'Faili “{0}” ei saa laadida, sest tema formaat ei ole toetatud',
    },
    INVOICE: {
        SEARCH_BY_ALL_INVOICES_TEXT_FIELDS: 'Otsi arvet numbri järgi',
        DATE: 'Kuupäev',
        NUMBER: 'Number',
        PRICE: 'Summa',
        DOWNLOAD: 'Lae alla (PDF)'
    },
    INVOICE_SORT_DATE_ASC: 'Kuupäev: kasvav',
    INVOICE_SORT_DATE_DESC: 'Kuupäev: kahanev',
    INVOICE_SORT_TOTAL_ASC: 'Summa: kasvav',
    INVOICE_SORT_TOTAL_DESC: 'Summa: kahanev',
    CANCEL: 'Tühista',
    APPROVE: 'Kinnita',
    YES: 'Jah',
    NO: 'Ei',
    SAVE_CHANGES: 'Kinnita tellimus',
    SAVE_CHANGES_ADDRESS_BOOK: 'Salvesta muudatused',
    BREADCRUMB: {
        SHIPMENTS: 'Saadetised',
        SHIPMENTS_ADD: 'Lisa uus saadetis',
        EDIT_SHIPMENT_DRAFT_NO: 'Muuda saadetise number {0} mustandit',
        PRICE_REQUESTS: 'Hinnapäringud',
        PRICE_REQUESTS_ADD: 'Lisa uus hinnapäring',
        EDIT_PRICE_REQUEST_DRAFT_NO: 'Muuda hinnapäringu mustandit nr {0}',
        PRICE_REQUEST: 'Hinnapäring',
        COMPANY_PROFILE: 'Ettevõtte profiil',
        TEMPLATES: 'Mallid',
        TEMPLATES_ADD: 'Lisa uus mall',
        EDIT_TEMPLATE: 'Muuda malli',
        CLAIM: 'Reklamatsioon',
        CLAIM_ADD: 'Lisa reklamatsioon',
        EDIT_CLAIM_DRAFT_NO: 'Muuda reklamatsiooni mustandit'
    },
    DAY: 'päevas',
    WEEK: 'nädalas',
    MONTH: 'kuus',
    YEAR: 'aastas',
    SHIPMENTS_SCENARIO: {
        FILTER: {
            FILTER: 'Filtreeri',
            CLOSE_FILTER: 'Sulge filter',
            CLEAR_ALL_FIELDS: 'Tühjenda kõik väljad',
            SHIPMENT_NR: 'Saadetise nr',
            REFERENCE_NR: 'Viitenumber',
            PICK_UP_FROM: 'Korje riik',
            SELECT_COUNTRY: 'Vali riik',
            PICK_UP_DATE: 'Korje kuupäev',
            DELIVERY_TO: 'Tarne riik',
            DELIVERY_DATE: 'Tarne kuupäev',
            STATUS: 'Staatus',
            SELECT_STATUS: 'Vali staatus',
            OOOPS: 'Oi, tulemusi kahjuks ei leitud :(',
            WE_SEARCHED: 'Me otsisime maa alt ja maa pealt, aga ei suutnud kahjuks leida sinu otsingule vastavaid tulemusi.',
            TRY_DIFFERENT: 'Proovi teisi filtri tingimusi või eemalda mõned filtreeringud.',
            INVALID_DATE: 'Kehtetu kuupäev',
            PICKUP_COMPANY: 'Korje ettevõte',
            DELIVERY_COMPANY: 'Tarne ettevõte',
            HELLO_USERNAME: 'Tere, {0}!',
            NO_SHIPMENTS_HERE: 'Pole veel saadetisi? Parandame selle vea!',
            MAKE_A_FEW_SELECTIONS: 'Tee mõned valikud, täida väljad, ja su saadetis on teel!',
            NEED_HELP: 'Vajad abi? Võta ühendust: ',
            PSST_DONT_WANT_TO_ADD: 'Psst! Ei taha veel midagi lisada? Tühjenda valik, et näha kogu oma ettevõtte saadetisi!',
            HERE_YOU_WILL_SEE: 'Siin näed enda lisatud hinnapäringuid.',
            START_ADDING_A_NEW_SHIPMENT: 'Alusta uue saadetise või hinnapäringu lisamist klikates kollasel nupul seal üleval!',
            EXPORT_XLS: 'Lae otsingu tulemused alla (.xlsx)'
        },
    },
    RELEASE_FROM_TOP: 'Eemalda olulistest',
    DOWNLOAD_LABELS: 'Laadi sildid alla',
    DOWNLOAD_CMR: 'Laadi alla CMR',
    COPY_SHIPMENT: 'Kopeeri',
    CANCEL_SHIPMENT: 'Tühista saadetis',
    COPY_PRICE_REQUEST: 'Kopeeri hinnapäring',
    CANCEL_PRICE_REQUEST: 'Tühista hinnapäring',
    SHIPMENT_DETAILS: 'Saadetise detailid',
    BASED_ON: 'Aluseks',
    COST: 'Maksumus',
    COST_TOOLTIP: 'Hind ei sisalda käibemaksu',
    WHERE_IS_MY_SHIPMENT: 'Kus mu saadetis on?',
    DETAILED_SHIPMENT_EVENTS: 'Detailne saadetise teekond',
    DOCUMENTS: 'Dokumendid',
    ADD_NEW_DOCUMENT: 'Lisa uus dokument',
    NOT_SELECTED: 'Ei ole valitud',
    DRAFT: 'Mustand',
    CANCELED: 'Tühistatud',
    ACCEPTED: 'Kinnitatud',
    REJECTED: 'Tagasi lükatud',
    MISSING_DATA: 'Info puudub',
    SHIPMENT_CREATED: 'Loodud',
    WAITING_CONFIRMED: 'Kinnituse ootel',
    CONFIRMED: 'Töös',
    PROCESSING: 'Edastatud partnerile',
    IN_TRANSIT: 'Transiidis',
    DELIVERING: 'Transiidis',
    DELIVERED: 'Tarnitud',
    LAST_DELIVERED: 'Viimati kohale toimetatud',
    WAITING_READINESS_REQUEST: 'Valmisoleku päringu ootel',
    WAITING_FOR_CONFIRMATION: 'Kinnitamise ootel',
    EXPIRED: 'Aegunud',
    PENDING_PRICES: 'Hindade ootel',
    NOT_KNOWN: 'Pole määratud',
    ADD_NEW_SHIPMENT: 'Lisa uus saadetis',
    ADD_NEW_PRICE_REQUEST: 'Lisa uus hinnapäring',
    ADD_NEW_CLAIM: 'Lisa reklamatsioon',
    ADD_NEW_SHIPMENT_FROM_TEMPLATE: 'Lisa uus saadetis mallist',
    ADD_NEW_SHIPMENT_FROM_EXCEL: 'Lisa saadetised Exceli mallist',
    EDIT_SHIPMENT_DRAFT_NO: 'Muuda saadetise number {0} mustandit',
    ADD_NEW_TEMPLATE: 'Lisa uus mall',
    EDIT_TEMPLATE_DRAFT_NAME: 'Muuda malli',
    ADD_NEW: 'Lisa uus',
    SWAP: 'Vaheta',
    ADDRESSBOOK: {
        FILTER: {
            INPUT_TEXT: 'Otsi kõigist aadressi väljadest',
            BUTTON: 'Otsi'
        },
        ADDRESSES: 'aadressi',
        ONE_ADDRESS: 'aadress',
        IMPORT_MODAL_TITLE: 'Impordi aadressid Exceli failist',
        IMPORT_MODAL_TITLE_CORRECT_ROWS: '{0} aadressi imporditi Exceli failist',
        IMPORT_MODAL_TITLE_INCORRECT_ROWS: '{0} aadressi imporditakse Exceli failist peale impordi nupu vajutamist',
        IMPORT_MODAL_TITLE_SMALL: 'Korraga saab lisada ainult ühe Exceli faili.',
        TOOLTIPS: {
            ADDRESS_NOTE_TOOLTIP: 'tooltip',
            ADDRESS_REFERENCE_TOOLTIP: 'tooltip',
        },
        EDIT_ADDRESS: 'Muuda aadressi',
        ADDRESS_NAME: 'Nimetus',
        COMPANY_NAME: 'Ettevõtte nimi',
        ADDRESS: 'Aadress',
        COUNTRY: 'Riik',
        CITY: 'Linn',
        CONTACT_PERSON: 'Kontaktisik',
        CONTACT_NUMBER: 'Kontakttelefoni number',
        CONTACT_EMAIL: 'Email',
        DELETE_BUTTON: 'Kustuta aadress',
        DELETE_CONFIRM_TEXT: 'Kas oled kindel, et soovid kustutada aadressi "{0}"?',
        BTN_IMPORT: 'Impordi aadressid',
        BTN_IMPORT_FINISH: 'Ok, ma mõistan',
        BTN_IMPORT_CORRECT_ROWS: 'Impordi õige informatsiooniga read',
        BTN_IMPORT_DOWNLOAD_ERROR_XLSX: 'Laadi alla fail vigaste ridadega (.xlsx)',
        ERROR_MALFORMED_ROWS_TEXT: 'Puuduliku infoga read',
        ERROR_DUPLICATED_ROWS_TEXT: 'Järgmised read juba eksisteerisid aadressiraamatus',
        MISSING: 'puudub',
        IMPORT_EXCEL_FILE: 'Impordi Excelist',
        DOWNLOAD_EXCEL_TEMPLATE: 'Laadi alla Exceli mall',
        ADD_NEW_ADDRESS: 'Lisa uus aadress',
        SAVE_ADDRESS: 'Salvesta aadress',
        ADDRESS_ADDED_TO_ADDRESS_BOOK: 'Aadress lisatud aadressiraamatusse',
    },
    PRICE_REQUEST: {
        TOOLTIP: {
            EXPIRES_SOON: 'Aegub peagi',
            CANCELED: 'Hinnapäringu kehtivus on möödas või tühistatud',
            CAN_NOT_BE_CANCELED: 'Seda hinnapäringut ei saa tühistada, sest ta on juba töös'
        },
        PRICE_ORDER_NOW: 'Telli kohe',
        WE_CAN_OFFER_PRICES: 'Saame pakkuda järgnevaid hindasid',
        NO_PRICES: 'Võimalike hindade nägemiseks täida kauba ja aadresside info.',
        NO_AUTOMATIC_PRICES: "Kahjuks automaatselt hinda ei leitud. Jätka päringu täitmist ning peale selle kinnitamist vastame sulle, et mida me saame pakkuda.",
        CREATE_SHIPMENT_ALERT_HEADING: 'Uue tellimuse sisestamine',
        CREATE_SHIPMENT_ALERT: 'Tellimuse vormistamiseks avatakse Teile uue tellimuse sisestuse vorm, mis on eeltäidetud hinnapakkumise andmetega.\n {0}Palun arvestage, et hind kehtib samadele andmetele, mis olid esitatud hinnapakkumises.{1} Hinnapakkumisest vormistatud tellimusel ei arvuta süsteem automaatselt uut hinda kui andmeid on muudetud, selleks tuleb ühendust võtta logistika spetsialistiga',
        CANCELED: 'Hinnapäring on tühistatud',
        REJECTED: 'Hinnapäring on tagasi lükatud.',
        TITLE_ACCEPTED: 'Aitäh! Sinu valitud hind {0}€ on kinnitatud!',
        TITLE_ACCEPTED_VALID_UNTIL: 'Pane tähele, et see hind on kehtiv kuni {0}.',
        TITLE_ACCEPTED_TEXT: 'Saad nüüd lisada uue saadetise, kus on samad andmed kui sellel hinnapäringul.',
        TITLE_EXPIRED: 'This price request is expired!',
        HEADER_REJECTED_TITLE_0: 'Kahju, sa tühistasid meie pakkumise',
        HEADER_REJECTED_TITLE_1: 'Kahju, sa loobusid meie pakkumisest :(',
        HEADER_REJECTED_TITLE_1_SMALL: 'Aga sellest pole midagi, me mõistame. ',
        HEADER_REJECTED_TITLE_1_1_SMALL: 'Kui tahad teada veel mõnda hinda, lihtsalt alusta \n uut hinnapäringut. Me anname rõõmuga parima võimaliku hinna!',
        PLEASE_START_A_NEW_PRICE_REQUEST: 'Please start a new price request or copy this price request to get new and updated \n prices for your delivery.',
        HEADER_EXPIRED_OFFERS: 'Price request included following prices, you did not choose a price for you:',
        HEADER_EXPIRED_OFFER: 'Price request included a price you chose:',
        OPERATOR_COMMENT: 'Operaatori kommentaar: {0}',
        ADD_NEW_SHIPMENT: 'Vormista tellimus',
        ALREADY_GENERATED_SHIPMENTS: 'Sellest hinnapäringust oled juba algatanud saadetised:',
        INSURANCE: 'Kindlustus',
        ITEMS_VALUE: 'Kauba väärtus',
        CONDITION: 'Seisukord',
        PRICE: 'Hind',
        SAVE_AS_DRAFT_SUCCESS: 'Hinnapäring on mustandina salvestatud',
        ADD_COMMENTS_REGARDING_THE_PRICE_REQUEST: 'Lisa hinnapäringu kohta kommentaarid',
        ADD_A_COMMENT: 'Lisa kommentaar',
        ADD_AN_E_MAIL_WHERE_WE_WILL_SEND_THE_OFFER: 'Lisa e-post, kuhu saadame hinnapakkumise',
        IM_LOOKING_FOR_PRICES_FOR_30_DAYS: "Soovin, et valitud hind kehtib 30 päeva",
        IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS: "Soovin hinda regulaarsetele saadetistele",
        IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS_TOOLTIP: "Kui plaanid sellist saadetist regulaarselt saata, saame tõenäoliselt pakkuda parema hinna. Sellisel juhul palun täida allolev vorm, et saaksime sellega arvestada.",
        PLEASE_ADD_THE_NUMBER_OF_TIMES_AND_PERIOD: 'Lisa kordade arv ja periood',
        SEND_PRICE_REQUEST: 'Saada hinnapäring',
        ADD_ITEMS_TO_YOUR_PRICE_REQUEST: 'Lisa kaubad hinnapäringusse',
        OFFERED_PRICES: '{0} hinda',
        OFFERED_PRICE: '{0} hind',
        FILTER: {
            NUMBER: 'Hinnapäringu number'
        },
        SIDEBAR: {
            DETAILS: 'Hinnapäringu detailid',
            INSURANCE: 'Kindlustus',
            ITEMS_VALUE: 'Kauba väärtus',
            CONDITION: 'Seisukord',
            DESCRIPTION: 'Kirjeldus',
            REGULAR_SHIPMENTS: 'Regulaarsed tarned',
            REGULARITY: 'Regulaarsus',
            STATUSES: 'Hinnapäringu staatus',
            ACTIONS: 'Hinnapäringu tühistamine ja kopeerimine',
            TIMES_PER: 'korda'
        },
        NUMBER: 'Nr',
        PICK_UP_DATE: 'Korje kuupäev',
        DELIVERY_DATE: 'Tarne kuupäev',
        VALID_UNTIL_DATE: 'Kehtiv kuni',
        HEADER_TITLE_1: 'Me tegeleme sinu hinnapäringule hindade leidmisega!',
        HEADER_TITLE_1_SMALL: 'Me anname sulle teada siin ja e-maili teel, kui me oleme leidnud hinnad selle päringu jaoks.',
        EMAIL_NOTIFICATIONS: 'E-post, kuhu saadame hinnapakkumise',
        COMMENTS_TITLE: 'Hinnapäringu kohta kommentaarid',
        CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_PRICE_REQUEST:
            'Klikka ühel või mitmel ülaloleval pakendil, et oma hinnapäringusse kaupasid lisada',
        TIMES: 'korda',
        PER: '',
        SELECT_PERIOD: 'Vali periood',
        EMAIL_WHERE_WE_WILL_SEND_THE_OFFER: 'E-post, kuhu saadame hinnapakkumised',
        I_WANT_TO_INSURE_MY_ITEMS: 'Tahan oma kaupasid kindlustada',
        TOTAL_VALUE_OF_ITEMS: 'Kaupade koguväärtus',
        PLEASE_ADD_THE_DESCRIPTION_OF_YOUR_ITEMS: 'Palun lisa oma kaupade kirjeldus',
        TIMES_AND_PERIOD: 'Regulaarsus ja periood',
        CONFIRM_PRICE_REQUEST: 'Kinnita hinnapäring',
        CANCEL_PRICE_REQUEST_BTN: 'Tühista hinnapäring',
        CANCEL_PRICE_REQUEST: 'Tühista hinnapäring',
        CANCEL_CONFIRMATION: 'Kas oled kindel, et soovid tühistada hinnapäringu "PR-{0}"?',
        CANCEL_SUCCESS: 'Hinnapäring on edukalt tühistatud.',
        DAYS_DELIVERY: 'päevane tarne',
        WORKING_DAY_DELIVERY: 'tööpäev',
        WORKING_DAYS_DELIVERY: 'tööpäeva',
        ESTIMATED_DELIVERY: 'Eeldatav kohaletoimetamine',
        SAME_DAY_DELIVERY: 'Sama päeva tarne',
        ON_TIME_DELIVERY: 'Tarnekindlus',
        THIS_OPTION_IS_CHOSEN: 'See pakkumine on valitud',
        THIS_OPTION_UNSELECT: 'Tühista valik',
        CHOOSE_THIS_OPTION: 'Vali see pakkumine',
        ADD_AN_EMAIL: 'Lisa siia üks või mitu e-posti aadressi, eraldades need komadega. Me saadame nendele e-postidele pakkumise, kui see on valmis.',
        PRICE_REQUEST_VALID_UNTIL: 'Hinnapäring on kehtiv kuni',
        APPROVE_OFFER: 'Kinnitan ja telli hiljem',
        APPROVE_OFFER_ORDER_NOW: 'Kinnitan ja telli kohe',
        APPROVE_SUCCESS: 'Pakkumine on edukalt kinnitatud',
        REJECT_THIS_OFFER: 'Lükka pakkumine tagasi',
        REJECT_SUCCESS: 'Pakkumine on edukalt tagasi lükatud',
        PRICE_TOO_HIGH: 'Hind on liiga kõrge',
        SHIPMENT_POSTPONED: 'Saatmine edasilükatud',
        DELIVERY_NOT_NEEDED: 'Ei ole vaja saata',
        ASKED_AS_REFERENCE_PRICE: 'Küsitud võrdlushinnaks',
        OTHER: 'Muu',
        OFFER_REJECTION_DIALOG_TITLE: 'Palun anna meile teada, miks sa hinnapakkumise tagasi lükkasid',
        YOUR_REASON_FOR_REJECTION: 'Tagasilükkamise põhjus',
        SEND_YOUR_ANSWER: 'Saada oma vastus',
        PLEASE_COMMENT_YOUR_CHOICE: 'Palun kommenteeri oma valikut',
        REASON_FOR_REJECTION_HAS_TO_BE_SPECIFIED: 'Palun täida tagasilükkamise põhjus',
        OFFER_APPROVAL_DIALOG_TITLE: 'Kas sa oled kindel, et tahad kinnitada hinna {0}€?',
        OFFER_APPROVAL_DIALOG_TEXT: 'Saadetise selle hinnapäringu põhjal saad luua peale hinna kinnitamist.',
        PICK_ONE_PRICE_ERROR: 'Palun vali hind, mida soovid sellele hinnapäringule kinnitada',
        COMMENT_RELATED_TO_PRICE: 'Hinnaga seotud kommentaar',
    },
    TEMPLATE: {
        TEMPLATE_NAME: 'Nimi',
        TEMPLATES: 'Mallid',
        TEMPLATE: 'Malli',
        TEMPLATES_ADD: 'Lisa uus mall',
        SEARCH_BY_ALL_TEMPLATES_TEXT_FIELDS: 'Otsi malli nime või aadressiinfo järgi',
        DELETE_BUTTON: 'Kustuta',
        DELETE_CONFIRM_TEXT: 'Oled sa kindel, et soovid kustutada malli "{0}"?',
        DELETE_SUCCESS: 'Mall on kustutatud',
        DELETE_ERROR: 'Malli kustutamisel tekkis viga',
        ADD_ITEMS_TO_YOUR_TEMPLATE: 'Muuda kaupasid',
        SAVE_TEMPLATE: 'Salvesta muudatused',
        ADD_TEMPLATE_NAME: 'Lisa mallile nimi',
        CHANGE_TEMPLATE_NAME: 'Muuda malli nime',
        TEMPLATE_NAME_LABEL: 'Malli nimi',
        TEMPLATE_NAME_TOOLTIP: 'Malli nime näidatakse, kui sa lood malli põhjal uut saadetist. Pane siia meeldejääv nimi, mis aitab sul õiget malli valida.',
        SAVE_SUCCESS: 'Mall on salvestatud',
        ADDRESSES: {
            EDIT_ROUTE_ADDRESSES: 'Muuda malli aadresse'
        },
        TEMPLATE_CREATED: 'Mall loodud {0}',
        SEARCH_FOR_A_TEMPLATE_TITLE: 'Otsi malli:',
    },
    SHIPMENT: {
        STAR_TOOLTIP: 'Tõsta saadetis nimekirjas esile',
        PICK_ONE_PRICE_ERROR: 'Palun vali kinnitamiseks üks hind',
        SEND: 'Saada',
        SEND_MESSAGE: 'Saada sõnum',
        HAS_FILES: 'Saadetisele on lisatud fail',
        COMMENTS: 'Sõnumid',
        HAS_COMMENT: 'Saadetises on sõnumeid',
        NEW_COMMENT: '1 uus sõnum',
        NEW_COMMENTS: '{0} uut sõnumit',
        NO_NEW_COMMENTS: 'Uusi sõnumeid ei ole',
        ADD_A_COMMENT: 'Lisa sõnum',
        CLEAR_THE_FIELD: 'Tühjenda väli',
        SHIPMENT_TYPE: 'Saada/võta vastu saadetis',
        TYPE: {
            SENDER: 'Saadan saadetist',
            RECEIVER: 'Võtan vastu saadetise'
        },
        YOU_HAVE_UNSAVED_CHANGES: 'Sul on salvestamata muudatusi.',
        DISCARD_SHIPMENT: 'Loobu saadetisest',
        TITLE_ADD_NEW_SHIPMENT_FROM_TITLE: 'Loo uus saadetis mallist',
        BTN_ADD_SHIPMENT: 'Loo saadetis',
        FROM_TEMPLATE_DESCRIPTION: 'Mallide lisamiseks ja haldamiseks ava päisest kasutaja menüüs “Mallid“',
        BASED_ON_SHIPMENT: 'Põhineb saadetisel {0}. ',
        SHIPMENT_CREATED: 'Saadetis loodud {0}',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Korje tagaluugiga autoga',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK_DELIVERY: 'Tarne tagaluugiga autoga',
        I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK_TOOLTIP: 'Veok, mille tagaosas on mehhaaniline tõstuk. See tõstuk on kaupade laadimiseks maapinnalt või laadimisalalt autosse või vastupidi.',
        SAVE_SHIPMENT_AS_A_TEMPLATE: 'Salvesta saadetis {0} mallina',
        THIS_SHIPMENT_IS_NOT_SAVED_AS_TEMPLATE: 'Saadetis ei ole salvestatud mallina',
        THIS_SHIPMENT_IS_SAVED_AS_TEMPLATE: 'See saadetis põhineb mallil',
        REMOVE_TEMPLATE_CONFIRM_TEXT: 'Kas oled kindel, et soovid kustutada malli "{0}"?',
        SAVE_THIS_SHIPMENT_AS_TEMPLATE_BUTTON: 'Salvesta saadetis mallina',
        CANCEL_CONFIRMATION_DIALOG_TEXT: 'Kas oled kindel, et soovid tühistada? Muudatusi ei salvestata.',
        REMOVE_TEMPLATE_BUTTON: 'Kustuta mall',
        REMOVE_FROM_TEMPLATES: 'Halda malle',
        NAME_YOUR_TEMPLATE: 'Malli nimi',
        MANAGE_TEMPLATES: 'Mallid',
        SAVE_TEMPLATE: 'Salvesta mall',
        TEMPLATE_NAME: 'Malli nimi',
        NOTIFICATIONS_FOR_THIS_SHIPMENT: 'Saadetise teavitused on',
        SEND_SHIPMENT: 'Edasta saadetis',
        SAVE_AS_DRAFT: 'Salvesta mustand',
        SAVE_AS_DRAFT_SUCCESS: 'Saadetis on salvestatud mustandina',
        DELETE_DRAFT: 'Kustuta mustand',
        DELETE_DRAFT_CONFIRMATION: 'Kas oled kindel, et soovid kustutada saadetise number {0} mustandit?',
        DELETE_DRAFT_SUCCESS: 'Saadetise mustand on edukalt kustutatud',
        SHIPMENTS: 'Saadetised',
        SHIPMENT: 'Saadetis',
        SHIPMENT_NR: 'Saadetis nr',
        REF_NR: 'Viitenumber',
        REF_MISSING: 'Viitenr puudub',
        CARRIER_MISSING: 'Vedaja puudub',
        PICKUP_MISSING: 'Korje aadress puudub',
        DELIVERY_MISSING: 'Tarne aadress puudub',
        DATE_MISSING: 'Aeg puudub',
        ADD_REFERENCE_NUMBER: 'Lisa viitenumber',
        YOUR_SHIPMENTS_PRICE: "Sinu saadetise hind",
        YOUR_SHIPMENTS_PRICE_DESC: 'Näidatud hinnad on indikatiivsed ja võivad muutuda kui saadetav kaup ei vasta sisestatud väärtustele',
        NO_PRICES: 'Saadetise hindade nägemiseks täida kauba ja aadresside info.',
        NO_AUTOMATIC_PRICES: "Kahjuks me ei toeta sinu saadetisele automaatselt hinna leidmist. Operaator vaatab saadetist peale selle kinnitamist ja annab teada, mida me saame pakkuda.",
        AUTOMATIC_PRICES_IN_PROGRESS: 'Me tegeleme sinu kaupadele ja suundadele parima hinna leidmisega!',
        REFERENCE_NUMBER: 'Viitenumber',
        REFERENCE_NUMBER_TOOLTIP: 'Saadetisega seotud viitenumber kliendisüsteemis.',
        NOTIFY_ME_ABOUT_THIS_SHIPMENT: 'Saada mulle saadetise kohta teavitusi',
        SAVE_THIS_SHIPMENT_AS_TEMPLATE: 'Salvesta saadetis mallina',
        SAVE_THIS_SHIPMENT_AS_TEMPLATE_TOOLTIP: 'Salvestatud malli saab kasutada sarnaste andmetega vedude sisestamiseks.',
        EARLIEST_PICKUP_DATE: 'Varaseim korje kuupäev',
        LATEST_PICKUP_DATE: 'Hiliseim korje kuupäev',
        EARLIEST_DELIVERY_DATE: 'Varaseim tarne kuupäev',
        LATEST_DELIVERY_DATE: 'Hiliseim tarne kuupäev',
        FIXED_PICKUP_DATE: 'Kindel korje kuupäev',
        FIXED_DELIVERY_DATE: 'Kindel tarne kuupäev',
        EARLIEST_PICKUP_TIME: 'Varaseim korje kellaaeg',
        LATEST_PICKUP_TIME: 'Hiliseim korje kellaaeg',
        EARLIEST_DELIVERY_TIME: 'Varaseim tarne kellaaeg',
        LATEST_DELIVERY_TIME: 'Hiliseim tarne kellaaeg',
        FIXED_PICKUP_TIME: 'Kindel korje aeg',
        FIXED_DELIVERY_TIME: 'Kindel tarne aeg',
        PLEASE_CHECK_YOUR_SHIPMENT_INFORMATION_BEFORE_CONFIRMING: 'Enne kinnitamist palun kontrolli oma saadetise info üle',
        SIDEBAR: {
            ACTIONS: 'Saadetise kopeerimine',
        },
        DATES: {
            ADD_PICKUP_DELIVERY_DATES: "Lisa korje ja tarne ajad",
            PICKUP_DATES: "Korje aeg",
            DELIVERY_DATES: "Tarne aeg",
            PICK_PICKUP_DATES: "Lisa varaseim ja hiliseim korje aeg",
            PICK_DELIVERY_DATES: "Lisa varaseim ja hiliseim tarne aeg",
            PICK_PICKUP_FIXED_DATE: "Lisa fikseeritud korje aeg",
            PICK_DELIVERY_FIXED_DATE: "Lisa fikseeritud tarne aeg"
        },
        NAME_THIS_SHIPMENT_TEMPLATE: 'Anna mallile nimi',
        ADDRESSES: {
            ADD_ADDRESS: 'Lisa aadress',
            UPDATE_ADDRESS: 'Uuenda aadressi',
            ADD_ROUTE_ADDRESSES: 'Lisa aadressid',
            REQUIRED_FIELDS_ARE_MARKED_WITH: 'Kohustuslikud väljad on tähistatud tärniga',
            WHERE_TO_PICK_UP_FROM: 'Saatja',
            WHERE_TO_DELIVER: 'Saaja',
            RECENTLY_USED_ADDRESSES: 'Viimati kasutatud aadressid',
            CHOOSE_FROM_ADDRESS_BOOK: 'Vali aadressiraamatust',
            ADD_MANUALLY: 'Lisa käsitsi',
            CLEAR_ALL_FIELDS: 'Tühjenda kõik väljad',
            SAVE_THIS_ADDRESS: 'Salvesta aadress:',
            NAME_THIS_ADDRESS: 'Nimeta aadress',
            SENDER_AND_DELIVERY_ADDRESSES: 'Korje ja tarne aadressid',
            TYPE_HERE_THE_ADDRESS_AND_SELECT_IT_FROM_GOOGLE_RESULTS_OR_FROM_ADDRESS_BOOK:
                'Sisesta aadress või ettevõtte nimi',
            COUNTRY: 'Riik',
            CITY: 'Linn',
            POSTCODE: 'Sihtnumber',
            COMPANY_NAME: 'Ettevõtte nimi',
            CONTACT_PERSON: 'Kontaktisik',
            CONTACT_NUMBER: 'Kontakttelefoni number',
            EMAIL: 'E-post',
            REFERENCE: 'Viitenumber',
            LOADING_REFERENCE: 'Korje viitenumber',
            LOADING_REFERENCE_TOOLTIP: 'Pealelaadimise asukoha spetsiifiline kood vms number.',
            UNLOADING_REFERENCE: 'Tarne viitenumber',
            UNLOADING_REFERENCE_TOOLTIP: 'Mahalaadimise asukoha spetsiifiline kood vms number.',
            ADD_AN_ALTERNATIVE_ADDRESS: 'Lisa alternatiivne aadress',
            ADDRESS_DETAILS: 'Aadressi lisainfo',
            ADDRESS: 'Aadress',
            ADD_MORE_INFORMATION: 'Lisa informatsiooni',
            CLOSE_MORE_INFORMATION: 'Sulge lisainformatsioon',
            SENDER: {
                INFORMATION: 'Saatja info',
                ADDRESS: 'Aadress',
                CONTACT_PERSON: 'Saatja kontaktisik',
                ADDRESS_DETAILS: 'Saatja aadressi lisainfo',
                ADDRESS_DETAILS_TOOLTIP: 'Kaupade pealelaadimise asukoha detailid (maja külg, uks, terminal vms)',
                ALTERNATIVE_ADDRESS: 'Alternatiivne korje aadress',
                ALTERNATIVE_CONTACT_PERSON: 'Alternatiivne korje kontaktisik',
                ALTERNATIVE_ADDRESS_DETAILS: 'Alternatiivse korje aadressi lisainfo',
                ADD_AN_ALTERNATIVE_ADDRESS: 'Lisa alternatiivne korje aadress',
            },
            RECEIVER: {
                INFORMATION: 'Saaja info',
                ADDRESS: 'Aadress',
                CONTACT_PERSON: 'Saaja kontaktisik',
                ADDRESS_DETAILS: 'Saaja aadressi lisainfo',
                ADDRESS_DETAILS_TOOLTIP: 'Kaupade mahalaadimise asukoha detailid (maja külg, uks, terminal vms)',
                ALTERNATIVE_ADDRESS: 'Alternatiivne tarne aadress',
                ALTERNATIVE_CONTACT_PERSON: 'Alternatiivne tarne kontaktisik',
                ALTERNATIVE_ADDRESS_DETAILS: 'Alternatiivse tarne aadressi lisainfo',
                ADD_AN_ALTERNATIVE_ADDRESS: 'Lisa alternatiivne tarne aadress',
            },
        },
        ADD_ITEMS_TO_YOUR_SHIPMENT: 'Lisa kaubad',
        CLICK_ON_THE_ITEM_YOU_WANT_TO_ADD: 'Kliki kauba peal, mida soovid lisada',
        ITEMS_ADDED: 'Lisatud kaubad',
        CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_SHIPMENT:
            'Kliki üht või mitut kaupa, et need oma saadetisele lisada',
        I_NEED_PICKUP_WITH_A_TAIL_LIFT_TRUCK: 'Soovin korjet tagaluugiga autoga',
        I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK: 'Soovin tarnet tagaluugiga autoga',
        ADD_A_COMMENT_REGARDING_THE_ITEMS_ADDED: 'Lisa kommentaar kauba kohta',
        DOCUMENTS: {
            DELETE_TOOLTIP: 'Seda dokumenti ei saa kustutada, sest see loodi operatoori poolt',
        },
        CMR_NOT_AVAILABLE: 'Seda dokumenti ei saa veel alla laadida, sest vedaja ei ole fikseeritud',
        POD_NOT_AVAILABLE: 'Seda dokumenti ei saa veel alla laadida, sest tarnetõend (POD) pole veel loodud',
        ITEM: {
            SELECT_TYPE: 'Vali tüüp',
            TOTAL: 'Kokku',
            DETAILS: {
                PC: 'tk',
                TYPE: 'Liik',
                LENGTH: 'Pikkus',
                LENGTH_TOOLTIP: 'Pakendi pikkus on sellel pakendi tüübil standardiseeritud',
                WIDTH: 'Laius',
                WIDTH_TOOLTIP: 'Pakendi laius on sellel pakendi tüübil standardiseeritud',
                HEIGHT: 'Kõrgus',
                VOLUME: 'Maht',
                WEIGHT_PC: 'Kaal/tk',
                TOTAL_WEIGHT: 'Kogukaal',
                LDM: 'LDM',
                LDM_TOOLTIP: 'Laadimismeeter (jooksev meeter haagise pikkusest täislaiuses ja -kõrguses)',
                REFERENCE_NUMBER: 'Viitenumber',
                PRODUCT_DESCRIPTION: 'Kauba kirjeldus',
                UN_NO: 'UN kood',
                UN_NO_TOOLTIP: 'ÜRO tunnusnumber, mis identifitseerib ohtlikud ained ja esemed',
                UN: 'UN',
                UN_CODE: 'UN kood',
                PG: 'Pakendigrupp',
                PG_TOOLTIP: 'Ohtlikud kaubad jagatakse oma ohtlikkuse taseme järgi 3 peamisse gruppi',
                CLASS: 'Klass',
                CLASS_TOOLTIP: 'Ohtlike kaupade klassifikatsiooni numbrid',
                DANGEROUS_GOODS: 'Ohtlik kaup',
                COLD_FROZEN: 'Jahutatud/külmutatud',
                STACKABLE: 'Virnastatav',
                DELICATE: 'Õrn kaup',
                MIN_TEMP: 'Min temp',
                MIN_TEMP_TOOLTIP: 'Minimaalne temperatuur, mida kaup vajab transpordiks',
                MAX_TEMP: 'Max temp',
                MAX_TEMP_TOOLTIP: 'Maksimaalne temperatuur, mida kaup vajab transpordiks',
                PROPER_SHIPPING_NAME: 'Ohtliku veose tunnusnimetus',
                PROPER_SHIPPING_NAME_TOOLTIP: 'Nimetus ohtlike kaupade omaduste ja koostise kirjeldamiseks',
                PROPER_SHIPPING_NAME_PLACEHOLDER: 'Must pulber, kokkusurutud, või püssirohi',
                PACKAGE_INFO: 'Väljale "Pikkus" märkida pikima külje mõõt kui pakk on virnastatav.'
            },
            TYPE: {
                PALLET: 'Alus',
                PACKAGE: 'Pakk',
                TRUCK: 'Täiskoorem',
                OTHER: 'LDM',
                CONTAINER: 'Konteiner',
                MACHINE: 'Machine'
            },
            SUBTYPE: {
                EUR_PALLET: 'EUR alus',
                FIN_PALLET: 'FIN alus',
                PALLET: 'Alus',
                PACKAGE: 'Pakk',
                BOX: 'Kast',
                FULL_TRUCK_LOAD: 'Täiskoorem',
                LONG_PALLET: 'Pikk alus',
                HALF_PALLET: 'Poolik alus',
                LDM: 'LDM',
                CONTAINER_20_DC: '20’DC',
                CONTAINER_40_DC: '40’DC',
                CONTAINER_40_HC: '40’HC',
                CONTAINER_OTHER: 'Muu konteiner',
                MACHINE: 'Machine'
            },
            CLASS: {
                EXPLOSIVES: 'Lõhkeained',
                GASES: 'Gaasid',
                FLAMMABLE_LIQUIDS: 'Kergestisüttivad vedelikud',
                FLAMMABLE_SOLIDS: 'Kergestisüttivad tahked ained',
                OXIDIZERS: 'Oksüdeerivad ained',
                TOXIC_SUBSTANCES: 'Mürgised ained',
                RADIOACTIVE: 'Radioaktiivsed materjalid',
                CORROSIVES: 'Sööbivad ained',
                MISCELLANOUS: 'Muud ohtlikud ained ja esemed',
            },
            PG: {
                PG1: 'Pakendirühm I: väga ohtlikud',
                PG2: 'Pakendirühm II: mõõdukalt ohtlikud',
                PG3: 'Pakendirühm III: vähem ohtlikud',
            },
        },
        DELETE_DOCUMENT: 'Kustuta dokument',
        DELETE_CONFIRM_TEXT: 'Kas oled kindel, et soovid kustutada dokumendi "{0}"?',
        ADD_DOCUMENT_MODAL_TITLE: 'Add new document',
        ADD_DOCUMENT_BUTTON: 'Add document',
        CUSTOMER_CONFIRMED: 'Kliendi kinnitatud: {0}',
        PICKUP_ON: 'Korje: {0}',
        PICKUP_FROM: 'Korje alates: {0}',
        PICKUP_BETWEEN: 'Korje vahemikus: {0} ja {1}',
        DELIVERY_ON: 'Tarne: {0}',
        DELIVERY_FROM: 'Tarne alates: {0}',
        DELIVERY_BETWEEN: 'Tarne vahemikus: {0} ja {1}',
        IMPORT_MODAL_TITLE: 'Impordi saadetised Exceli failist',
        IMPORT_MODAL_TITLE_CORRECT_ROWS: '{0} saadetist edukalt imporditud',
        IMPORT_MODAL_TITLE_INCORRECT_ROWS: 'Ühtegi saadetist ei imporditud Exceli failist. Palun parandage vead ja laadige fail uuesti.',
        IMPORT_MODAL_TITLE_SMALL: 'Korraga saab lisada ainult ühe Exceli faili.',
        BTN_IMPORT: 'Impordi saadetised',
        BTN_IMPORT_FINISH: 'Ok, sain aru',
        BTN_IMPORT_CORRECT_ROWS: 'Ok, laen faili uuesti',
        BTN_IMPORT_DOWNLOAD_ERROR_XLSX: 'Download file with error rows (.xlsx)',
        ERROR_MALFORMED_ROWS_TEXT: 'Allpool on näha mõned read, kus olid vead ja seega neid ei saa importida ',
        ERROR_DUPLICATED_ROWS_TEXT: 'Allpool on näha mõned read, mis on juba saadetistena süsteemis olemas ja seega ei saa neid importida',
        EXCEL_ROW_NUMBER: 'Exceli rida',
        EXCEL_ROW_MISSING_WRONG_INFO: 'Puuduv või vale informatsioon',
        EXCEL_ROW_EXISTS: 'Saadetis, mis juba eksisteerib',
        EXCEL_IMPORT_ERRORS: {
            SENDER_REQUIRED: 'Korje ettevõtte nimi on puudu',
            SENDER_ADDRESS_REQUIRED: 'Korje aadress on puudu',
            SENDER_CITY_REQUIRED: 'Korje linn on puudu',
            SENDER_POST_CODE_REQUIRED: 'Korje sithnumber on puudu',
            SENDER_COUNTRY_REQUIRED: 'Korje riik on puudu',
            SENDER_CONTACT_PERSON_REQUIRED: 'Korje kontaktisik on puudu',
            SENDER_CONTACT_PHONE_REQUIRED: 'Korje kontaktisiku telefoninumber on puudu',
            RECEIVER_REQUIRED: 'Tarne ettevõtte nimi on puudu',
            RECEIVER_ADDRESS_REQUIRED: 'Tarne aadress on puudu',
            RECEIVER_CITY_REQUIRED: 'Tarne linn on puudu',
            RECEIVER_POST_CODE_REQUIRED: 'Tarne sithnumber on puudu',
            RECEIVER_COUNTRY_REQUIRED: 'Tarne riik on puudu',
            RECEIVER_CONTACT_PERSON_REQUIRED: 'Tarne kontaktisik on puudu',
            RECEIVER_CONTACT_PHONE_REQUIRED: 'Tarne kontaktisiku telefoninumber on puudu',
            PICKUP_DATE_ERROR: 'Korje kuupäev on puudu',
            DELIVERY_DATE_ERROR: 'Tarne kuupäev on puudu',
            DELIVERY_DATE_IS_EARLIER_THAN_PICKUP_ERROR: 'Tarne kuupäev on varasem kui korje kuupäev',
            QUANTITY_ERROR: 'Kogus on puudu',
            PACKAGE_ERROR: 'Kaup on puudu',
            WEIGHT_ERROR: 'Kaal on puudu',
            WIDTH_ERROR: 'Laius on puudu',
            LENGTH_ERROR: 'Pikkus on puudu',
            HEIGHT_ERROR: 'Kõrgus on puudu',
            PICKUP_DATE_IN_PAST: 'Korje kuupäev on minevikus',
            LDM_ERROR: 'Laadimismeetrid on puudu'
        },
        BTN_DOWNLOAD_XLSX_TEMPLATE: 'Laadi mall alla (.xlsx)',
    },
    SWITCH: {
        YES: 'JAH',
        NO: 'EI',
    },
    VALIDATION: {
        IS_REQUIRED_TEXT: 'Kohustuslikud väljad on märgitud *',
        INVALID_VALUE: 'Ebasobiv väärtus',
        THIS_FIELD_IS_REQUIRED: 'See väli on kohustuslik',
        YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ITEM: 'Vähemalt üks peab olema valitud',
        AT_LEAST_ONE_ITEM_NEEDS_TO_BE_ADDED_IN_THE_SHIPMENT:
            'Palun lisa vähemalt 1 kaup mida saata',
        SOME_REQUIRED_FIELDS_ARE_NOT_FILLED: 'Mõned nõutud väljad on täitmata',
        NEW_SHIPMENT_VALIDATION_ERROR: 'Saadetist ei saa salvestada, sest osa vajalikku infot on puudu.',
        INVALID_PHONE: 'Ebasobiv telefoninumber',
        INVALID_EMAIL: 'Ebasobiv e-posti aadress',
        SELECTED_DATE_IS_WRONG: 'Valitud kuupäev ei sobi',
        SELECTED_EARLIEST_DATE_IS_WRONG: 'Palun kontrolli varaseimat kuupäeva',
        SELECTED_ARRIVAL_DATE_IS_WRONG: 'Palun kontrolli varaseimat tarnekuupäeva',
        TEMPLATE_NAME: 'Malli nimi peab olema vähemalt 3 tähte'
    },
    UNITS: {
        PC: 'tk'
    },
    COMPANY: {
        OFFICES:"Kontorite ja ladude aadressid",
        IS_PRIMARY_OFFICE: "Peamine kontor / ladu",
        EDIT_OFFICE_ADDRESS: "Sisesta kontori / lao aadress ning kontaktandmed",
        OFFICE_DELETE_CONFIRMATION:'Kas oled kindel, et soovid aadressi kustutada?',
        OFFICE_DELETE_SUCCESS:'Kontori või lao aadress on kustutatud!',
        CLIENT_CODE: "Kliendikood",
        CLIENT_CODE_TOOLTIP: 'Saate jagada seda oma tarnijatele, et nad saaksid sisestada transpordi tellimusi.',
        ORDER_URL: 'Tellimuse link',
        ORDER_URL_COPIED: 'Tellimuse link kopeeritud',
        COMPANY: 'Company',
        COMPANY_NAME: 'Ettevõtte nimi',
        COMPANY_PROFILE: 'Ettevõtte profiil',
        COMPANY_DETAILS: 'Ettevõtte detailid',
        EDIT_DETAILS: 'Muuda detaile',
        WORKING_HOURS: 'Tööaeg',
        VAT_NUMBER: 'KMKR nr',
        REGISTRY_CODE: 'Registrikood',
        CONTACT_PERSON: 'Kontaktisik',
        EDIT_PERSON: 'Muuda isikut',
        PHONE_NO: 'Telefoninumber',
        EMAIL: 'E-post',
        ADDRESSES: 'Aadressid',
        OFFICIAL_ADDRESS: 'Ettevõtte ametlik aadress',
        EDIT_OFFICIAL_ADDRESS: 'Muuda ettevõtte ametlikku aadressi',
        MAIN_ADDRESS: 'Ettevõtte peamine aadress',
        EDIT_MAIN_ADDRESS: 'Muuda ettevõtte peamist aadressi',
        EDIT_INVOICE_ADDRESS: 'Muuda arve aadressi',
        INVOICE_CONFIGURATION: 'Arve seadistus',
        INVOICE_COMPANY_DETAILS: 'Arve ettevõtte detailid',
        INVOICE_CONTACT_PERSON: 'Arve kontaktisik',
        INVOICE_RECEIVER_EMAIL: 'Arve saaja e-post',
        INVOICE_TYPE: 'Arve tüüp',
        DUE_DATE_DAYS: 'Arve tähtaeg',
        INVOICE_ADDRESS: 'Arve aadress',
        ADDRESS: 'Aadress',
        CITY: 'Linn',
        REGION: 'Piirkond',
        POSTAL_CODE: 'Sihtnumber',
        COUNTRY: 'Riik',
        EDIT: 'Muuda',
        DETAILED: 'Detailne',
        REGULAR: 'Lihtsustatud',
        APPLY_THIS_INFORMATION_TO_MAIN_ADDRESS: 'Salvesta see info ka ettvõtte peamiseks aadressiks',
        APPLY_THIS_INFORMATION_TO_INVOICE_ADDRESS: 'Salvesta see info ka arve aadressiks',
        APPLY_THIS_INFORMATION_TO_INVOICE_DETAILS: 'Salvesta see info ka arve ettevõtte detailideks',
        APPLY_THIS_INFORMATION_TO_INVOICE_CONTACT: 'Salvesta see info ka arve kontaktisikule',
        DAYS: 'päeva',
        EDIT_COMPANY_DETAILS: 'Muuda ettevõtte detaile',
        EDIT_INVOICE_COMPANY_DETAILS: 'Muuda arve ettevõtte detaile',
        EDIT_CONTACT_PERSON: 'Muuda kontaktisikut',
        EDIT_INVOICE_CONTACT_PERSON: 'Muuda arve kontaktisikut',
        SAVE_CHANGES: 'Salvesta muudatused',
    },
    CLAIMS: "Reklamatsioonid",
    CLAIM: {
        ADD_NEW_FROM_SHIPMENT:'Lisa saadetisele uus reklamatsioon:',
        ADD_NEW_FROM_SHIPMENT_DESCRIPTION: 'Selleks, et lisada reklamatsioon, pead leidma õige saadetise.',
        SEARCH_FOR_A_SHIPMENT_TITLE:'Otsi saadetist numbri järgi:',
        SEARCH_BY_KEYWORD: "Otsi reklamatsiooni märksõna abil",
        PLEASE_CONFIRM: "Esita reklamatsioon",
        CONFIRM_TEXT: "Palun veendu, et reklamatsioonis esitatud andmed oleks õiged!",
        DOWNLOAD_PDF: "Lae all PDF",
        DAMAGE_COST: "Kahjunõue",
        NUMBER: "Number",
        SAVE_AS_DRAFT_SUCCESS: 'Reklamatsiooni on mustandina salvestatud',
        DELETE_DRAFT_SUCCESS: 'Reklamatsiooni mustand edukalt kustutatud',
        DELETE_DRAFT_CONFIRMATION: 'Kas oled kindel, et soovid kustutada reklamatsiooni mustandit?',
        COMPANY_INFO_TITLE: 'Reklamatsiooni esitaja andmed',
        GENERAL_INFO: 'Üldinfo',
        SHIPMENT_NR: 'Saadetise number',
        CLAIM_DATE: 'Reklamatsiooni esitamise kuupäev',
        DIRECTION: {
            TITLE: 'Transpordi suund',
            EXPORT: 'Eksport',
            IMPORT: 'Import'
        },
        COMPANY: {
            "NAME": "Esitaja nimi",
            "ADDRESS": "Aadress",
            "CONTACT": "Kontaktisik",
            "EMAIL": "E-mail",
            "PHONE": "Telefoni nr",
            "IBAN": "Arveldusarve nr ja pank"
        },
        SIDEBAR: {
            DETAILS: "Reklamatsiooni detailid",
            STATUSES: "Reklamatsiooni staatus",
        },
        DATE: "Esitamise kuupäev",
        SHIPMENT_NUMBER: "Saadetise number",
        REASON: "Reklamatsiooni põhjus",
        REASON_ERROR: "Vähemalt üks põhjus on kohustuslik valida",
        REASONS: {
            "FULLY_LOST": "Saadetise täielik kaotsiminek",
            "FULLY_DAMAGED": "Saadetise täielik kahjustumine",
            "PARTLY_LOST": "Saadetise osaline puudujääk",
            "PARTLY_DAMAGED": "Saadetise osaline kahjustumine",
            "WAS_LATE": "Saadetise hilinemine",
        },
        REASON_FULLY_TITLE: "Kogu saadetise kirjeldus",
        REASON_PARTLY_TITLE: "Osaliselt kahjustunud või kaotsi läinud saadetise kirjeldus",
        SEND_CLAIM: "Saada reklamatsioon",
        DESCRIPTION_TITLE: "Kahju avastamise aeg, kirjeldus ning suurus (€)",
        DESCRIPTION: "Kahju avastamise aeg ja kirjeldus",
        COST: "Kahju suurus (€)",
        SHIPMENT_INFO: {
            DESCRIPTION: "Kaubanimetus",
            QUANTITY: "Pakkeüksuste arv",
            PACKAGE_TYPE: "Pakkeviis",
            WEIGHT: "Brutokaal /kg",
            COST: "Saadetise väärtus / €"
        },
        FILES_ADDED: {
            attachedCmr: 'Allkirjastatud veokiri',
            attachedInvoices: 'Kogu saadetise ostu - või müügiarve(d)',
            attachedExpenseDocuments: 'Parandusega seotud kuluarve(d)',
            attachedLabels: 'Pakkeleht',
            attachedPictures: 'Fotod kahjustunud toodetest, kui ka pakendist',
            attachedOtherDocuments: 'Muud kahjude suurust tõendavad dokumendid',
        },
        STATUS_Draft: 'Mustand',
        STATUS_Processing: 'Menetluses',
        STATUS_Accepted: 'Aktsepteeritud',
        STATUS_Rejected: 'Tagasi lükatud',
        STATUS_DECISION: 'Otsus',
        STATUS_DESCRIPTION: 'Hüvitamise otsus'
    },
    One_office_required: 'Palun vali vähemalt üks asukoht!'
};
