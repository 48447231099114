import React, { ReactElement, FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';

import { BaseProps } from 'shared/types';
import { ShipmentPayload } from 'shared/types/shipments/shipments';

import { BellIcon, BookmarkIcon, EyeIcon, Indicator, Typography } from 'components';

import { theme } from 'theme';
import { useCommonStyles } from 'shared/styles/common';
import { routeTemplates } from 'routes';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../shared/hooks/useUser';
import { UserAction, UserActionType } from '../../shared/constants/user/userRights';
import { hasPermission } from '../../shared/functions/hasPermission';

type ShipmentHeaderProps = {
  // FIXME: It would be good to have the shipment id available in the shipment object itself,
  // but at the moment the backend doesn't return the id within the shipment object
  shipmentId?: string;
  shipment?: ShipmentPayload;
} & BaseProps;

const useStyles = makeStyles({
  onOffInactive: {
    cursor: 'pointer',
    color: theme.palette.custom.veryDarkViolet,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  onOffActive: {
    fontWeight: 700,
    cursor: 'default',
  },
});

const ShipmentHeader: FC<ShipmentHeaderProps> = ({ shipment = {}, shipmentId }): ReactElement => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { templateName } = shipment;

  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [templateNameState, setTemplateNameState] = useState('');

  const toggleNotifications = () => {
    setNotificationsEnabled(!notificationsEnabled);
  };

  useEffect(() => {
    setTemplateNameState(templateName ?? '');
  }, [templateName]);
  const user = useUser();
  return (
    <>
      <Box
        bgcolor={theme.palette.custom.veryLightGrayAlt}
        borderRadius={16}
        padding={5}
        display="flex"
        justifyContent="space-between"
      >
        <Indicator active icon={<EyeIcon />} className={commonClasses.hidden}>
          <Typography variant="body2" component="p">
            {t('SEEN_BY_THE_OPERATOR')}
          </Typography>
        </Indicator>
        <Indicator
          active={!!templateNameState}
          icon={<BookmarkIcon />}
          className={commonClasses.mAuto}
        >
          <Box>
            <Typography variant="body2" component="p">
              {t(
                templateNameState
                  ? 'SHIPMENT.THIS_SHIPMENT_IS_SAVED_AS_TEMPLATE'
                  : 'SHIPMENT.THIS_SHIPMENT_IS_NOT_SAVED_AS_TEMPLATE',
              )}
            </Typography>
            {!!templateNameState && (
              <Typography variant="body2" fontWeight="bold">
                {templateNameState}
              </Typography>
            )}
            {hasPermission(user, UserAction.shipments, UserActionType.Edit, 'shipments') && (
              <Typography
                variant="body2"
                link
                onClick={() =>
                  templateNameState
                    ? history.push(`${routeTemplates}`)
                    : history.push(`${routeTemplates}/new-template/?shipmentId=${shipmentId}`)
                }
              >
                {t(
                  templateNameState
                    ? 'SHIPMENT.REMOVE_FROM_TEMPLATES'
                    : 'SHIPMENT.SAVE_THIS_SHIPMENT_AS_TEMPLATE',
                )}
              </Typography>
            )}
          </Box>
        </Indicator>
        <Indicator
          active={notificationsEnabled}
          icon={<BellIcon />}
          className={commonClasses.hidden}
        >
          <Box>
            <Typography variant="body2" component="p">
              {t('SHIPMENT.NOTIFICATIONS_FOR_THIS_SHIPMENT')}
            </Typography>
            <Typography variant="body2">
              <span
                role="presentation"
                onClick={!notificationsEnabled ? toggleNotifications : undefined}
                onKeyDown={!notificationsEnabled ? toggleNotifications : undefined}
                className={notificationsEnabled ? classes.onOffActive : classes.onOffInactive}
              >
                {t('ON')}
              </span>{' '}
              /{' '}
              <span
                role="presentation"
                onClick={notificationsEnabled ? toggleNotifications : undefined}
                onKeyDown={notificationsEnabled ? toggleNotifications : undefined}
                className={!notificationsEnabled ? classes.onOffActive : classes.onOffInactive}
              >
                {t('OFF')}
              </span>
            </Typography>
          </Box>
        </Indicator>
      </Box>
    </>
  );
};

export default ShipmentHeader;
