import React, { FC, ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import Box, { BoxProps } from '@material-ui/core/Box';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { ShipmentDocument } from 'shared/types/shipments/shipments';
import { generateKey } from 'shared/functions/generateKey';
import {
  BinIcon,
  Dialog,
  FileDropZone,
  IconWrapper,
  Indicator,
  PlusIcon,
  Separator,
  Typography,
} from 'components';
import Tooltip from '@material-ui/core/Tooltip';
import { useParams } from 'react-router-dom';
import { useFiles } from 'shared/services/fileService';
import { PriceRequestPropsParams } from 'scenes/PriceRequest/PriceRequest';
import { useUser } from 'shared/hooks/useUser';
import { hasPermission } from 'shared/functions/hasPermission';
import { UserAction, UserActionType } from 'shared/constants/user/userRights';
import { BiLock, BiLockOpen } from 'react-icons/all';
import clsx from 'clsx';

type ShipmentParams = { shipmentId: string };

type ShipmentDocumentsProps = {
  documents: ShipmentDocument[];
  downloadFile: (fileData: ShipmentDocument) => void;
  fetchFiles?: () => void;
  readOnly?: boolean;
  boxProps?: BoxProps;
  objectId?: number;
  fileObject: 'Shipment' | 'PriceRequest' | 'ClaimDocument';
} & BaseProps;

type DeletableDocumentProps = {
  fileName: string;
  allowDelete: boolean;
  id: number;
};

const useStyles = makeStyles({
  heading: {
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
  fileName: {
    display: 'block',
    wordBreak: 'break-all',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  fileNameLocked: {
    display: 'block',
    wordBreak: 'break-all',
  },
  binIcon: {
    minWidth: 15,
  },
  addNewDocument: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:hover div': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const ShipmentDocuments: FC<ShipmentDocumentsProps> = ({
  documents,
  fetchFiles,
  readOnly,
  boxProps = {},
  downloadFile,
  fileObject,
  objectId,
}): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { deleteFile, toggleFileLocking } = useFiles();
  const user = useUser();
  const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(false);
  const [showLockFileDialog, setShowLockFileDialog] = useState(false);
  const [showAddFileDialog, setShowAddFileDialog] = useState(false);
  const [deleteFileInfo, setDeleteFileInfo] = useState<DeletableDocumentProps | null>(null);
  const { shipmentId } = useParams<ShipmentParams>();
  const { priceRequestId } = useParams<PriceRequestPropsParams>();
  const fileDropZoneRef = useRef<{
    uploadAcceptedFiles: (objectId: number) => void;
  }>(null);

  const uploadId = () => {
    if (fileObject === 'Shipment') {
      return parseInt(shipmentId, 10);
    }
    if (fileObject === 'PriceRequest') {
      return parseInt(priceRequestId, 10);
    }
    return objectId ?? 0;
  };

  const toggleShowDeleteDialog = () => {
    setShowDeleteFileDialog(!showDeleteFileDialog);
  };

  const toggleShowLockingDialog = () => {
    setShowLockFileDialog(!showLockFileDialog);
  };

  const toggleShowAddFileDialog = () => {
    setShowAddFileDialog(!showAddFileDialog);
  };

  const confirmDocumentDeleteAction = (allowDelete: boolean, id: number, fileName: string) => {
    if (allowDelete) {
      setDeleteFileInfo({ allowDelete, id, fileName });
      toggleShowDeleteDialog();
    }
  };

  const confirmDocumentLockingAction = (allowDelete: boolean, id: number, fileName: string) => {
    if (allowDelete) {
      setDeleteFileInfo({ allowDelete, id, fileName });
      toggleShowLockingDialog();
    }
  };

  const deleteBtnTooltip: string = t('SHIPMENT.DOCUMENTS.DELETE_TOOLTIP');

  const deleteDocument = async () => {
    if (!fetchFiles || readOnly) {
      return;
    }

    const sId = uploadId();
    if (deleteFileInfo && deleteFileInfo.allowDelete && sId) {
      try {
        await deleteFile({
          objectId: sId,
          object: fileObject,
          fileId: deleteFileInfo.id,
        });
        toggleShowDeleteDialog();
        fetchFiles();
      } catch (err) {
        // TODO: add error handling
      }
    }
  };

  const changeLocking = async () => {
    if (!fetchFiles || readOnly) {
      return;
    }

    const sId = uploadId();
    if (deleteFileInfo && deleteFileInfo.allowDelete && sId) {
      try {
        await toggleFileLocking({
          objectId: sId,
          object: fileObject,
          fileId: deleteFileInfo.id,
        });
        toggleShowLockingDialog();
        fetchFiles();
      } catch (err) {
        // TODO: add error handling
      }
    }
  };
  const deleteFileConfirmText: string = t('SHIPMENT.DELETE_CONFIRM_TEXT');
  const lockFileConfirmText: string = t('LOCKING_FILE_CONFIRM_TEXT');
  const uploadFiles = async () => {
    if (!fetchFiles || readOnly) {
      return;
    }

    const sId = uploadId();
    await fileDropZoneRef?.current?.uploadAcceptedFiles(sId);
    toggleShowAddFileDialog();
    fetchFiles();
  };

  let componentAction: UserAction = UserAction.shipments;
  if (fileObject === 'PriceRequest') {
    componentAction = UserAction.priceRequests;
  }
  if (fileObject === 'ClaimDocument') {
    componentAction = UserAction.claims;
  }
  return (
    <Box width={1} marginTop={12} {...boxProps}>
      {!readOnly && (
        <>
          <Dialog
            title={deleteFileConfirmText.replace('{0}', deleteFileInfo?.fileName ?? '')}
            maxWidth="sm"
            open={showDeleteFileDialog}
            handleClose={toggleShowDeleteDialog}
            actionCancelLabel={t('CANCEL')}
            actionAcceptLabel={t('SHIPMENT.DELETE_DOCUMENT')}
            onActionCancel={toggleShowDeleteDialog}
            onActionAccept={deleteDocument}
          />
          <Dialog
            title={lockFileConfirmText.replace('{0}', deleteFileInfo?.fileName ?? '')}
            maxWidth="sm"
            open={showLockFileDialog}
            handleClose={toggleShowLockingDialog}
            actionCancelLabel={t('CANCEL')}
            actionAcceptLabel={t('APPROVE')}
            onActionCancel={toggleShowLockingDialog}
            onActionAccept={changeLocking}
          />
          <Dialog
            title={t('SHIPMENT.ADD_DOCUMENT_MODAL_TITLE')}
            maxWidth="md"
            open={showAddFileDialog}
            handleClose={toggleShowAddFileDialog}
            actionCancelLabel={t('CANCEL')}
            actionAcceptLabel={t('SHIPMENT.ADD_DOCUMENT_BUTTON')}
            onActionCancel={toggleShowAddFileDialog}
            onActionAccept={uploadFiles}
          >
            <FileDropZone object={fileObject} ref={fileDropZoneRef} id={uploadId()} hideTitle />
          </Dialog>
        </>
      )}

      <Typography variant="subtitle2" component="p" className={classes.heading}>
        {t('DOCUMENTS')}
      </Typography>
      {documents
        .filter(({ size }) => size)
        .map((item, index, filteredDocuments) => {
          const mbSize = (item.size / 1000000).toFixed(2);
          const isLast = index >= filteredDocuments.length - 1;
          const isLockedForOthers = !item.allowToggleLocking && item.isLocked;
          return (
            <div key={generateKey(index, 'document_item')}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography
                  className={clsx(
                    !isLockedForOthers && classes.fileName,
                    isLockedForOthers && classes.fileNameLocked,
                  )}
                  variant="body2"
                  component={item.isLocked ? 'p' : 'a'}
                  onClick={() => (!isLockedForOthers ? downloadFile(item) : () => {})}
                >
                  {!readOnly &&
                    !isLockedForOthers &&
                    `${item.fileName} (${mbSize}MB, ${t('UPLOADED')} ${item.createdOn})`}
                  {readOnly || (isLockedForOthers && `${item.fileName} (${mbSize}MB)`)}
                </Typography>
              </Box>
              {isLockedForOthers && (
                <Box display="flex" justifyContent="end">
                  {' '}
                  <Typography variant="caption" fontStyle="italic" marginLeft={2}>
                    {t('FILE_IS_LOCKED')}
                    {item.createdBy}
                  </Typography>
                </Box>
              )}
              {!readOnly &&
                !isLockedForOthers &&
                hasPermission(user, componentAction, UserActionType.Edit, 'shipments') && (
                  <Box display="flex" justifyContent="end">
                    {item.allowToggleLocking && (
                      <Tooltip
                        title={
                          !item.isLocked
                            ? t('LOCK_FILE_TOOLTIP').toString()
                            : t('UNLOCK_FILE_TOOLTIP').toString()
                        }
                        arrow
                        enterDelay={200}
                        enterNextDelay={200}
                      >
                        <div>
                          <Typography
                            onClick={() =>
                              confirmDocumentLockingAction(
                                item.allowToggleLocking,
                                item.id,
                                item.fileName,
                              )
                            }
                            component="div"
                            variant="body2"
                          >
                            <IconWrapper
                              bgcolorHover={
                                item.allowToggleLocking
                                  ? theme.palette.custom.lightGrayishCyan
                                  : theme.palette.custom.veryLightGrayAlt3
                              }
                              marginLeft={1.75}
                            >
                              {!item.isLocked && (
                                <BiLock
                                  size={18}
                                  fill={
                                    item.allowToggleLocking
                                      ? theme.palette.custom.darkCyan
                                      : theme.palette.custom.veryDarkGrayAlt2
                                  }
                                />
                              )}
                              {item.isLocked && (
                                <BiLockOpen
                                  size={18}
                                  fill={
                                    item.allowToggleLocking
                                      ? theme.palette.custom.darkCyan
                                      : theme.palette.custom.veryDarkGrayAlt2
                                  }
                                />
                              )}
                            </IconWrapper>
                          </Typography>
                        </div>
                      </Tooltip>
                    )}
                    <Tooltip
                      title={!item.allowDelete ? deleteBtnTooltip : ''}
                      arrow
                      enterDelay={200}
                      enterNextDelay={200}
                    >
                      <div>
                        <Typography
                          onClick={() =>
                            confirmDocumentDeleteAction(item.allowDelete, item.id, item.fileName)
                          }
                          component="div"
                          variant="body2"
                        >
                          <IconWrapper
                            bgcolorHover={
                              item.allowDelete
                                ? theme.palette.custom.lightGrayishCyan
                                : theme.palette.custom.veryLightGrayAlt3
                            }
                            marginLeft={1.75}
                          >
                            <BinIcon
                              className={classes.binIcon}
                              fill={
                                item.allowDelete
                                  ? theme.palette.custom.darkCyan
                                  : theme.palette.custom.veryDarkGrayAlt2
                              }
                            />
                          </IconWrapper>
                        </Typography>
                      </div>
                    </Tooltip>
                  </Box>
                )}
              {!readOnly && (
                <Separator
                  bgcolor={isLast ? theme.palette.custom.gray : theme.palette.custom.lightGray}
                  my={4}
                />
              )}
            </div>
          );
        })}

      {!readOnly && hasPermission(user, componentAction, UserActionType.Edit, 'shipments') && (
        <Indicator active icon={<PlusIcon />} className={classes.addNewDocument}>
          <Typography variant="body2" onClick={() => toggleShowAddFileDialog()}>
            {t('ADD_NEW_DOCUMENT')}
          </Typography>
        </Indicator>
      )}
    </Box>
  );
};
export default ShipmentDocuments;
