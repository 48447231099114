import React, { ReactElement, FC, useEffect, useState, useContext } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { theme } from 'theme';
import { Address } from 'shared/types/addressBook/addressBook';
import { Labeled } from 'components';
import { useFormikError } from 'shared/hooks/useFormikError';
import { emptyPlacePrediction, PlacePrediction, usePlaces } from 'shared/services/places';
import { LoadingContact } from 'shared/types/loadingContact';
import { TextFieldSlowFormik } from 'components/CustomMaterial/TextField/TextField';
import { AddressAutocompleteContext } from '../../Shipment/ShipmentAddressEditable';

type AddressLine1OrLookupFieldsProps = {
  name: string;
  labelText: string;
  isRequired: boolean;
};

const useStyles = makeStyles(() => ({
  autocompleteListbox: {
    maxHeight: 'unset',
    '& li:last-of-type': {
      '& .MuiAutocomplete-groupUl': {
        position: 'relative',
        marginBottom: theme.spacing(4),
        '&:after': {
          position: 'absolute',
          content: "url('/images/powered-by-google-small.png')",
          bottom: theme.spacing(-5),
          left: theme.spacing(3),
        },
      },
    },
    '& .MuiListSubheader-sticky': {
      position: 'static',
    },
  },
}));

const GoogleSearchInput: FC<AddressLine1OrLookupFieldsProps> = ({
  name,
  labelText,
  isRequired,
}): ReactElement | null => {
  const { t } = useTranslation();
  const formik = useFormikContext<Address>();
  const classes = useStyles();

  const onAddressAutocomplete = useContext(AddressAutocompleteContext);

  const isError = useFormikError();

  const addressData = formik.values[name as keyof Address] as string | null;

  const placePredictionFromAddressString = (addressStr?: string): PlacePrediction | null => {
    return addressStr
      ? {
          ...emptyPlacePrediction,
          description: addressStr,
        }
      : null;
  };

  const [value, setValue] = React.useState<PlacePrediction | null>(
    placePredictionFromAddressString(addressData ?? ''),
  );
  const [inputValue, setInputValue] = React.useState('');

  const [debouncedInput] = useDebounce(inputValue, 500);

  const { fetchLoadingContactByPlaceId, fetchLoadingAddressSuggestions } = usePlaces();
  const [autoCompleteChoices, setAutoCompleteChoices] = useState<PlacePrediction[]>([]);

  const fieldName = `${name}`;

  useEffect(() => {
    if (!inputValue) {
      setAutoCompleteChoices([]);
    } else {
      fetchLoadingAddressSuggestions(inputValue)
        .then((result) => setAutoCompleteChoices(result.sort((a, b) => a.source - b.source)))
        .catch(() => setAutoCompleteChoices([]));
    }
  }, [debouncedInput]);

  const onAutoCompleteSelected = (choice: LoadingContact) => {
    if (choice) {
      formik.setFieldValue('country', choice.country);
      formik.setFieldValue('city', choice.city);
      formik.setFieldValue('postalCode', choice.postCode);
      formik.setFieldValue('addressLine1', choice.addressLine1);
      formik.setFieldValue('addressLine2', choice.addressLine2);
      formik.setFieldValue('addressLine3', choice.addressLine3);

      const moreInformationFieldsFilled =
        choice.companyName ||
        choice.contactPersonName ||
        choice.phone ||
        choice.email ||
        choice.reference ||
        choice.notes;

      if (onAddressAutocomplete && moreInformationFieldsFilled) {
        onAddressAutocomplete();
      }
    }
  };

  useEffect(() => {
    setValue(placePredictionFromAddressString(addressData ?? ''));
  }, [addressData]);

  useEffect(() => {
    if (value && value.placeId) {
      fetchLoadingContactByPlaceId(value).then((place) => {
        if (place) {
          onAutoCompleteSelected(place);
        }
      });
    }
  }, [value]);

  return (
    <Labeled error={isError(fieldName)} variant="variant2" text={labelText} required={isRequired}>
      <Autocomplete
        freeSolo
        getOptionLabel={(option: PlacePrediction | string) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={autoCompleteChoices}
        groupBy={(option) => option.sourceTranslation}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(_, newValue: PlacePrediction | string | null) => {
          if (typeof newValue === 'string') {
            // User hasn't chosen any suggested options
            formik.setFieldValue(fieldName, newValue);
          } else {
            // User has chosen one of the suggested options
            setValue(newValue);
          }
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextFieldSlowFormik
            {...params}
            inputProps={{ ...params.inputProps, autoComplete: 'custom-autocomplete' }}
            InputProps={{ ...params.InputProps, endAdornment: undefined, className: undefined }}
            fullWidth
            name={fieldName}
            error={isError(fieldName)}
            size="small"
            variant="variant6"
            autoComplete="off"
            placeholder={t(
              'SHIPMENT.ADDRESSES.TYPE_HERE_THE_ADDRESS_AND_SELECT_IT_FROM_GOOGLE_RESULTS_OR_FROM_ADDRESS_BOOK',
            )}
            showErrorLabel
          />
        )}
        renderOption={(option) => option.description}
        classes={{
          listbox: classes.autocompleteListbox,
        }}
      />
    </Labeled>
  );
};

export default GoogleSearchInput;
