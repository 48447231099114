export default {
    WELCOME_TEXT: "Hei, {0}!",
    OUT_OF_OFFICE: "{0} poissa toimistolta.",
    DASHBOARD_TEXT: "Kehitämme portaaliamme joka viikko ja kerromme sinulle, \n kun jotain uutta \n ja mielenkiintoista on ilmaantunut portaaliin!",
    PRIMARY_CONTACT: "Oma yhteyshenkilösi: ",
    READ_MORE: "Lue lisää",
    LESS: 'Vähemmän',
    CLOSE: 'Sulje',
    OPEN: "Avata",
    COPY: "Kopio",
    SELECTED: "valittu",
    CO2_EMISSIONS:"päästö",
    CO2_EMISSIONS_TOOLTIP: "CO2-jalanjälki lasketaan lähetyksen toimituksen jälkeen, ottaen huomioon reitin erityispiirteet.",
    CREATED_ON: 'luotu',
    REFRESH_DATA: 'Päivitä näkymä',
    TRY_FULL_SCREEN: 'Kokeile koko ruudun näkymää saadaksesi vielä paremman yleiskuvan',
    DATEPICKER_PLACEHOLDER: "pp.kk.yyyy",
    NOT_FOUND_HEADING: "Voi ei!",
    NOT_FOUND_DESC: "Etsimääsi sivua ei ole olemassa tai ",
    NOT_FOUND_DESC_2: "sinulla ei ole oikeuksia nähdä sitä.",
    NOT_FOUND_ERROR_CODE: "Virhekoodi: 404",
    FILTER_RESULT: "Suodatuksen tulos",
    FILTER_SEARCH_RESULT: "Hakutulokset ",
    ERROR_OUR_BUG: "Vika ei ole sinussa vaan meissä! Työskentelemme ongelman parissa ja se korjataan pian!",
    GO_TO_HOMEPAGE: "Siirry lähetysten sivulle",
    GO_BACK: 'Palaa takaisin',
    SEARCH_INSERT_KEYWORD: 'Syötä hakusana',
    FORGOT_PASSWORD: 'Unohditko salasanasi?',
    SEND_EMAIL: 'Lähettää sähköpostia',
    OPERATOR_SEEN: 'Operaattori on nähnyt',
    RESET_PASSWORD_BOX_TITLE: "Nollaa salasana",
    RESET_PASSWORD_BTN: "Muuta",
    FORGOT_PASSWORD_BOX_TITLE: "Unohditko kirjautumistiedot?",
    FORGOT_PASSWORD_BOX_SUCCESS: "Kiitos! Jos tunnistimme sinut, sinun pitäisi pian saada sähköpostiisi linkki salasanan vaihtoon.",
    IMPORT_ADDRESSES: 'Tuo osoitteita Excel-tiedostosta',
    LOGIN_BOX_TITLE: "Asiakasportaali",
    LOGIN_BOX_DESC: 'Nähdäksesi lähetyksesi ja niiden tilat kirjaudu asiakasportaaliimme.',
    INVITATION_BOX_TITLE : 'Luo uusi käyttäjä',
    LOGIN_FAILED: "Automaattinen kirjautuminen epäonnistui.",
    TERMS_OF_SERVICE: 'Palveluehdot',
    USERNAME: 'Käyttäjätunnus',
    PASSWORD: 'Salasana',
    PASSWORD_AGAIN: 'Salasana uudelleen',
    SIGN_IN: 'Kirjaudu sisään',
    FAILED_TO_LOG_IN: 'Kirjautuminen epäonnistui',
    PASSWORDS_DO_NOT_MATCH: 'Salasanat eivät täsmää',
    USER_NOT_CREATED: 'Käyttäjää ei luotu!',
    CREATE_USER: 'Luo käyttäjä',
    CREATE_USER_PASSWORD_ERROR: "Tarkista salasanan vaatimukset. (Vaaditaan vähintään yksi erikoismerkki, numero, pieni / iso kirjain.)",
    PASSWORD_HINT: 'Salasanan minimipituus on 8 merkkiä. Vaaditaan vähintään yksi erikoismerkki, numero, pieni / iso kirjain.',
    FAILED_TO_LOG_IN_BLOCKED: 'Tilisi on estetty. Ota yhteyttä osoitteeseen customer@laasone.com avataksesi tilisi lukituksen',
    MY_PROFILE: 'Oma profiili',
    THIS_IS_DEMO_ENVIRONMENT: 'Tämä on demoympäristö. Tänne lisättyjä tietoja ei käsitellä.',
    INVOICES: 'Laskut',
    WAREHOUSE: 'Varastonäkymä',
    ALL: 'Kaikki',
    INCOMING: 'Saapuva',
    OUTGOING: 'Lähtevä',
    YESTERDAY: 'Eilen ({0})',
    TODAY: 'Tänään ({0})',
    TOMORROW: 'Huomenna ({0})',
    ENTER_FULLSCREEN: 'Siirry koko näytön tilaan',
    LABEL_NOT_PRINTED: 'Kollilappuja ei ole tulostettu',
    LABEL_PRINTED: 'Kollilaput tulostettu',
    CMR_NOT_PRINTED: 'Rahtikirjaa ei ole tulostettu',
    CMR_PRINTED: 'Rahtikirja tulostettu',
    INVOICES_EXTRA: 'laskut',
    ADDRESS_BOOK: 'Osoitekirja',
    RESULTS_FROM_ADDRESS_BOOK: 'Tulokset osoitekirjasta: ',
    PRICE_REQUESTS: 'Tarjouspyynnöt',
    RESULTS_FROM_GOOGLE_MAPS: 'Google Mapsin tulokset: ',
    COMPANY_PROFILE: 'Yrityksen profiili',
    COMPANY_DETAILS: 'Yrityksen tiedot',
    LOG_OUT: 'Kirjaudu ulos',
    SHOW_ONLY_SHIPMENTS_CREATED_BY_ME: 'Näytä vain minun luomat lähetykset',
    SHOW_ONLY_SHIPMENTS_CREATED_BY_ME_TOOLTIP: 'Jos tämä valintaruutu on valittuna, tässä luettelossa näkyvät vain sinun luomasi lähetykset. Sos poistat tämän valinnan, kaikki yrityksesi lähetykset näkyvät täällä',
    SHOW_ONLY_PRICE_REQUESTS_CREATED_BY_ME: 'Näytä vain minun luomat tarjouspyynnöt',
    SHOW_ONLY_PRICE_REQUESTS_CREATED_BY_ME_TOOLTIP: 'Jos tämä valintaruutu on valittuna, tässä luettelossa näkyvät vain sinun luomasi tarjouspyynnöt. Jos poistat tämän valinnan, kaikki yrityksesi tekemät tarjouspyynnöt näkyvät tässä luettelossa',
    OPEN_FILTER: 'Avaa suodatin',
    SORT_BY: 'Lajittele',
    SELECT: 'Valitse',
    SHIPMENT_NUMBER: 'Lähetyksen nro',
    REFERENCE_NO: 'Viitenro',
    AMOUNT: 'Määrä',
    CARRIER: 'Kuljetusliike',
    PICKUP: 'Nouto',
    PICKUP_DATE: 'Noutopäivämäärä',
    PICKUP_ADDRESS: 'Nouto-osoite',
    DELIVERY: 'Toimitus',
    DELIVERY_DATE: 'Toimituspäivämäärä',
    DELIVERY_ADDRESS: 'Toimitusosoite',
    PICKUP_CONTACT: 'Noutokontakti',
    DELIVERY_CONTACT: 'Toimituskontakti',
    STATUS: 'Tila',
    DATE: 'Päivämäärä',
    SHOW: 'Näytä',
    UPLOADED: 'ladattu',
    CLOSE_ALERT: 'Sulje hälytys',
    LABELS_SENT_DETAILS: 'Etiketit lähetetty',
    LOCK_FILE_TOOLTIP: 'Lukitse tiedosto, jotta muut eivät voi ladata tai poistaa sitä',
    UNLOCK_FILE_TOOLTIP: 'Poista tiedoston lukitus, jotta muut voivat myös ladata tai poistaa sen',
    LOCKING_FILE_CONFIRM_TEXT: 'Oletko varma, että haluat muuttaa tiedoston lukitusta?',
    FILE_IS_LOCKED: 'Lukittu ',
    SHIPMENT_SORT_SHIPMENT_NO_DSC: 'Lähetysnumero: laskeva',
    SHIPMENT_SORT_SHIPMENT_NO_ASC: 'Lähetysnumero: nouseva',
    SHIPMENT_SORT_PICKUP_DATE_DSC: 'Noutopäivämäärä: laskeva',
    SHIPMENT_SORT_PICKUP_DATE_ASC: 'Noutopäivämäärä: nouseva',
    SHIPMENT_SORT_DELIVERY_DATE_DSC: 'Toimituspäivämäärä: laskeva',
    SHIPMENT_SORT_DELIVERY_DATE_ASC: 'Toimituspäivämäärä: nouseva',
    TEMPLATE_SORT_TEMPLATE_NAME_ASC: 'Mallin nimi: nouseva',
    TEMPLATE_SORT_TEMPLATE_NAME_DESC: 'Mallin nimi: laskeva',
    TEMPLATE_SORT_TEMPLATE_PICKUP_ASC: 'Noutoyhtiö: nouseva',
    TEMPLATE_SORT_TEMPLATE_PICKUP_DESC: 'Noutoyhtiö: laskeva',
    TEMPLATE_SORT_TEMPLATE_DELIVERY_ASC: 'Toimitusyhtiö: nouseva',
    TEMPLATE_SORT_TEMPLATE_DELIVERY_DESC: 'Toimitusyhtiö: laskeva',
    ADDRESSBOOK_SORT_NAME_ASC: 'Osoitteen nimi: nouseva',
    ADDRESSBOOK_SORT_NAME_DESC: 'Osoitteen nimi: laskeva',
    ADDRESSBOOK_SORT_COMPANY_ASC: 'Yhtiön nimi: nouseva',
    ADDRESSBOOK_SORT_COMPANY_DESC: 'Yhtiön nimi: laskeva',
    ADDRESSBOOK_SORT_COUNTRY_ASC: 'Maa: nouseva',
    ADDRESSBOOK_SORT_COUNTRY_DESC: 'Maa: laskeva',
    PRICE_REQUEST_SORT_SHIPMENT_NO_DSC: 'Tarjouspyynnön numero: laskeva',
    PRICE_REQUEST_SORT_SHIPMENT_NO_ASC: 'Tarjouspyynnön numero: nouseva',
    SHIPMENT_FILTER_SHIPMENT_NO: 'Lähetysnumero',
    SHIPMENT_FILTER_REFERENCE_NO: 'Viitenro',
    SHIPMENT_FILTER_PICKUP_FROM: 'Nouto kohteesta',
    SHIPMENT_FILTER_PICKUP_DATE: 'Noutopäivämäärä',
    SHIPMENT_FILTER_DELIVERY_TO: 'Toimitus kohteeseen',
    SHIPMENT_FILTER_DELIVERY_DATE: 'Toimituspäivämäärä',
    SHIPMENT_FILTER_STATUS: 'Tila',
    SEARCH: 'Haku',
    SELECT_COUNTRY: 'Valitse maa',
    DOWNLOAD_POD: 'Lataa POD {0}',
    COPY_TRACKING_LINK: 'Kopioi seurantalinkki',
    TRACKING_LINK_COPIED: 'Seurantalinkki kopioitu leikepöydälle',
    SEEN_BY_THE_OPERATOR: 'Operaattori nähnyt',
    ON: 'PÄÄLLÄ',
    OFF: 'POIS',
    PICKUP_AND_DELIVERY_DATES: 'Nouto- ja toimituspäivämäärät',
    EARLIEST_PICKUP: 'Aikaisin nouto',
    LATEST_PICKUP: 'Viimeisin nouto',
    EARLIEST_DELIVERY: 'Aikaisin toimitus',
    LATEST_DELIVERY: 'Viimeisin toimitus',
    FIXED_PICKUP: 'Kiinteä nouto',
    FIXED_DELIVERY: 'Kiinteä toimitus',
    ITEMS_IN_THE_SHIPMENT: 'Lähetyksen tuotteet',
    ITEMS_ADDED_PRICE_REQUEST: 'Tarjouspyynnön tuotteet',
    COMMENTS_REGARDING_THE_ITEMS: 'Tuotteisiin liittyvät kommentit',
    PICKUP_FROM: 'Nouto kohteesta',
    DELIVERY_TO: 'Toimitus kohteeseen',
    LENGTH: 'Pituus',
    HEIGHT_TOOLTIP: 'Pituus on kiinteä valitulle tuotetyypille',
    WIDTH: 'Leveys',
    WIDTH_TOOLTIP: 'Leveys on kiinteä valitulle tuotetyypille',
    HEIGHT: 'Korkeus',
    VOLUME: 'Tilavuus',
    WEIGHT_PC: 'Paino/kpl',
    TOTAL_WEIGHT: 'Kokonaispaino',
    LDM: 'LDM',
    PRODUCT_DESCRIPTION: 'Tuotekuvaus',
    DESCRIPTION: 'Kuvaus',
    LOCATION: 'Sijainti',
    UN_CODE: 'UN-koodi',
    PG: 'PG',
    CLASS: 'Luokka',
    COLD_FROZEN: 'Kylmä/pakaste',
    STACKABLE: 'Pinottavissa',
    DELICATE: 'Herkkä',
    PROPER_SHIPPING_NAME: 'Asianmukainen laivausnimi',
    YOU_ARE_LOGGED_OUT: 'Olet kirjautunut ulos',
    SIDEBAR_DASHBOARD: 'Etusivu',
    SIDEBAR_SHIPMENTS: 'Lähetykset',
    SIDEBAR_PRICE_REQUESTS: 'Tarjouspyynnöt',
    SIDEBAR_USERS: 'Käyttäjät',
    ADD_DOCUMENTS_TITLE: 'Lisää asiakirjoja',
    DASHBOARD: {
        LAST_MESSAGES: 'Viimeiset viestit',
        PRICE_REQUEST_SECTION: 'Tarjouspyynnöt, jotka vaativat huomiotasi',
        SHIPMENTS_SECTION: 'Lähetykset, jotka vaativat huomiotasi',
        NOT_CONFIRMED_PR: 'Ei vahvistettu ({0})',
        NOT_ORDERED_PR: 'Ei tilattu ({0})',
        CURRENT_MONTH_STATISTICS: 'Kuluvan kuukauden toimitettujen lähetyksien tilastot',
        INCOMING: 'SAAPUVAT',
        OUTGOING: 'LÄHTEVÄT',
        TOTAL_WEIGHT: 'KOKONAISPAINO',
        VOLUME: 'TILAVUUS',
        IN_COUNTRY_PERCENT: '{0}% lähtevistä lähetyksistä oli reitillä {1}-{1}',
        SENDING_COST: '{0} € oli kuljetuskustannuksesi',
    },
    USERS:{
        ADD_USER:'Kutsu uusi käyttäjä',
        EDIT_USER_TITLE:'Muokkaa käyttäjää',
        FIRST_NAME:'Etunimi',
        LAST_NAME:'Sukunimi',
        LIMITED_ACCESS:'Aseta käyttäjälle räätälöity pääsy',
        LIMITED_ACCESS_TOOLTIP:'Jos käyttäjäoikeuksia ei ole määritetty, käyttäjä näkee oletuksena kaikki portaalin tiedot.',
        USERNAME: 'Käyttäjänimi / sähköposti',
        PRIMARY_CONTACT: 'LaasOne:n ensisijainen yhteyshenkilö',
        BTN_SAVE_BUTTON: "Tallenna",
        RIGHTS:{
            NO_ACCESS: 'Ei pääsyä',
            VIEW: 'Vain katselu',
            ADD_EDIT: 'Lisää tai muokkaa'
        },
        INVITED: 'Odottaa',
        DELETE_SUCCESS: 'Käyttäjä poistettu',
        DELETE_CONFIRMATION: 'Haluatko varmasti poistaa käyttäjän?',
        BTN_DELETE: "Poista",
        TEXT_USER_INVITED: "Käyttäjän kutsu lähetetty",
        TEXT_USER_SAVED: "Käyttäjän tiedot tallennettu",
        LIMITED_ACCESS_LABEL: "Rajoitettu pääsy",
        SHOW_SHIPMENT_PRICE: "Näytä toimitusten hinta",
        INVITATION_URL_COPIED: "Kutsulinkki kopioitu! Jaa tämä linkki uudelle käyttäjälle, jotta hän voi viimeistellä rekisteröitymisen.",
        ACCESS_ALL_OFFICES: 'Pääsy kaikkien sijaintien lähetyksiin',
        ACCESS_ALL_OFFICES_TOOLTIP: 'Oletuksena käyttäjällä on pääsy kaikkiin sijaintitietoihin. Muokataksesi tätä valitse haluamasi sijainnit.',
        CHOOSE_OFFICES: 'Valitse sijainnit, joihin haluat antaa pääsyn',
        WAREHOUSE_WORKER: 'Käyttäjällä on pääsy vain varastonäkymään.',
        WAREHOUSE_WORKER_TOOLTIP: 'Rajoittaa käyttäjän pääsyn vain varastoon liittyvien tietojen katseluun.',
    },
    USERNAME_EXISTS: "Tämä käyttäjänimi on jo olemassa",
    REPLAY: 'Vastaa',
    DROPZONE: {
        DRAG_AND_DROP: 'Vedä ja pudota tiedosto tähän tai',
        CHOOSE_FROM_COMPUTER: 'valitse tietokoneeltasi',
        SUPPORTED_FILES: 'Tuetut tiedostotyypit: PDF, PNG, JPG, JPEG',
        SUPPORTED_FILES_XLS: 'Tuetut tiedostotyypit: XLSX',
        ERROR: 'Tiedoston “{0}” tiedostotyyppiä ei tueta',
    },
    INVOICE: {
        SEARCH_BY_ALL_INVOICES_TEXT_FIELDS: 'Hae lasku numeron perusteella',
        DATE: 'Laskun päivämäärä',
        NUMBER: 'Laskun numero',
        PRICE: 'Hinta',
        DOWNLOAD: 'Lataa (PDF)'
    },
    INVOICE_SORT_DATE_ASC: 'Päivämäärä: nouseva',
    INVOICE_SORT_DATE_DESC: 'Päivämäärä: laskeva',
    INVOICE_SORT_TOTAL_ASC: 'Yhteensä: nouseva',
    INVOICE_SORT_TOTAL_DESC: 'Yhteensä: laskeva',
    CANCEL: 'Peruuta',
    APPROVE: 'Hyväksy',
    YES: 'Kyllä',
    NO: 'Ei',
    SAVE_CHANGES: 'Tallenna muutokset',
    SAVE_CHANGES_ADDRESS_BOOK: 'Tallenna muutokset',
    BREADCRUMB: {
        SHIPMENTS: 'Lähetykset',
        SHIPMENTS_ADD: 'Lisää uusi lähetys',
        EDIT_SHIPMENT_DRAFT_NO: 'Muokkaa lähetysluonnosta nro {0}',
        PRICE_REQUESTS: 'Tarjouspyynnöt',
        PRICE_REQUESTS_ADD: 'Lisää uusi tarjouspyyntö',
        EDIT_PRICE_REQUEST_DRAFT_NO: 'Muokkaa tarjouspyynnöksen luonnosta nro  {0}',
        PRICE_REQUEST: 'Tarjouspyyntö',
        COMPANY_PROFILE: 'Yrityksen profiili',
        TEMPLATES: 'Mallit',
        TEMPLATES_ADD: 'Lisää uusi malli',
        EDIT_TEMPLATE: 'Muokkaa mallia',
        CLAIM: 'Valitus',
        CLAIM_ADD: 'Lisää valitus',
        EDIT_CLAIM_DRAFT_NO: 'Muuta valituksen muistutus'
    },
    DAY: 'päivä',
    WEEK: 'viikko',
    MONTH: 'kuukausi',
    YEAR: 'vuosi',
    SHIPMENTS_SCENARIO: {
        FILTER: {
            FILTER: 'Suodatin',
            CLOSE_FILTER: 'Sulje suodatin',
            CLEAR_ALL_FIELDS: 'Tyhjennä kaikki kentät',
            SHIPMENT_NR: 'Lähetysnro',
            REFERENCE_NR: 'Referenssinro',
            PICK_UP_FROM: 'Noutomaa',
            SELECT_COUNTRY: 'Valitse maa',
            PICK_UP_DATE: 'Noutopäivämäärä',
            DELIVERY_TO: 'Toimitusmaa',
            DELIVERY_DATE: 'Toimituspäivämäärä',
            STATUS: 'Tila',
            SELECT_STATUS: 'Valitse tila',
            OOOPS: 'Oho, ei tuloksia : (',
            WE_SEARCHED: 'Etsimme kaukaa ja laajalti, mutta emme löytäneet yhtään hakuasi vastaavaa lähetystä.',
            TRY_DIFFERENT: 'Kokeile eri suodatuskriteerejä tai poista joitakin suodattimia.',
            INVALID_DATE: 'Virheellinen päivämäärä',
            PICKUP_COMPANY: 'Noutoyhtiö',
            DELIVERY_COMPANY: 'Toimitusyhtiö',
            HELLO_USERNAME: 'Hei, {0}!',
            NO_SHIPMENTS_HERE: 'Ei lähetyksiä täällä? Korjataan tämä!',
            MAKE_A_FEW_SELECTIONS: 'Kokeile eri suodatuskriteerejä tai poista joitakin suodattimia!',
            NEED_HELP: 'Tarvitsetko apua? Ota yhteys: ',
            PSST_DONT_WANT_TO_ADD: 'Psst! Etkö halua lisätä vielä mitään? Poista rasti nähdäksesi yrityksesi lähetykset!',
            HERE_YOU_WILL_SEE: 'Tässä näet lisäämäsi tarjouspyynnöt.',
            START_ADDING_A_NEW_SHIPMENT: 'Aloita uuden kuljetuksen tai tarjouspyynnön lisääminen klikkaamalla keltaista painiketta tuolla ylhäällä!',
            EXPORT_XLS: 'Vie suodatuksen tulokset (.xlsx)'
        },
    },
    RELEASE_FROM_TOP: 'Vapauta ylhäältä',
    DOWNLOAD_LABELS: 'Lataa kollilaput',
    DOWNLOAD_CMR: 'Lataa Rahtikirja',
    COPY_SHIPMENT: 'Kopioi',
    CANCEL_SHIPMENT: 'Peruuta lähetys',
    COPY_PRICE_REQUEST: 'Kopioi tarjouspyyntö',
    CANCEL_PRICE_REQUEST: 'Peruuta tarjouspyyntö',
    SHIPMENT_DETAILS: 'Lähetyksen tiedot',
    BASED_ON: 'Perustuu',
    COST: 'Kustannus',
    COST_TOOLTIP: 'Hinta ei sisällä ALV:tä',
    WHERE_IS_MY_SHIPMENT: 'Missä lähetykseni on?',
    DETAILED_SHIPMENT_EVENTS: 'Yksityiskohtaiset lähetystapahtumat',
    DOCUMENTS: 'Asiakirjat',
    ADD_NEW_DOCUMENT: 'Lisää uusi asiakirja',
    NOT_SELECTED: 'Ei valittu',
    DRAFT: 'Luonnos',
    CANCELED: 'Peruutettu',
    MISSING_DATA: 'Puuttuvat tiedot',
    SHIPMENT_CREATED: 'Uusi',
    WAITING_CONFIRMED: 'Hyväksyttävä',
    CONFIRMED: 'Käsitellään',
    PROCESSING: 'Lähetetty kuljetusliikkeelle',
    IN_TRANSIT: 'Kuljetuksessa',
    DELIVERING: 'Toimitetaan',
    DELIVERED: 'Toimitettu',
    LAST_DELIVERED: 'Viimeksi toimitettu',
    WAITING_READINESS_REQUEST: 'Odottaa vahvistusta',
    WAITING_FOR_CONFIRMATION: 'Odottaa vahvistusta',
    ACCEPTED: 'Hyväksytty',
    REJECTED: 'Hylätty',
    EXPIRED: 'Vanhentunut',
    PENDING_PRICES: 'Odottaa vahvistusta',
    NOT_KNOWN: 'ei tiedossa',
    ADD_NEW_SHIPMENT: 'Lisää uusi lähetys',
    ADD_NEW_PRICE_REQUEST: 'Lisää uusi tarjouspyyntö',
    ADD_NEW_CLAIM: 'Lisää uusi vaatimus',
    ADD_NEW_SHIPMENT_FROM_TEMPLATE: 'Lisää uusi lähetys mallista',
    ADD_NEW_SHIPMENT_FROM_EXCEL: 'Lisää lähetyksiä Excel-mallista',
    EDIT_SHIPMENT_DRAFT_NO: 'Muokkaa lähetysluonnosta nro {0}',
    ADD_NEW_TEMPLATE: 'Lisää uusi malli',
    EDIT_TEMPLATE_DRAFT_NAME: 'Muokkaa mallia',
    ADD_NEW: 'Lisää uusi',
    SWAP: 'Vaihda',
    ADDRESSBOOK: {
        FILTER: {
            INPUT_TEXT: 'Haku kaikista osoitekentistä',
            BUTTON: 'Haku'
        },
        ADDRESSES: 'osoitteet',
        ONE_ADDRESS: 'osoite',
        IMPORT_MODAL_TITLE: 'Tuo osoitteita Excel-tiedostosta',
        IMPORT_MODAL_TITLE_CORRECT_ROWS: '{0} osoitetta tuotiin Excel-tiedostosta',
        IMPORT_MODAL_TITLE_INCORRECT_ROWS: '{0} osoitetta tullaan tuomaan Excel-tiedostosta',
        IMPORT_MODAL_TITLE_SMALL: 'Vain yksi Excel-tiedosto voidaan tuoda kerralla',
        TOOLTIPS: {
            ADDRESS_NOTE_TOOLTIP: 'työkaluvihje',
            ADDRESS_REFERENCE_TOOLTIP: 'työkaluvihje',
        },
        EDIT_ADDRESS: 'Muokkaa osoitetta',
        ADDRESS_NAME: 'Osoitteen nimi',
        COMPANY_NAME: 'Yrityksen nimi',
        ADDRESS: 'Osoite',
        COUNTRY: 'Maa',
        CITY: 'Kaupunki',
        CONTACT_PERSON: 'Yhteyshenkilö',
        CONTACT_NUMBER: 'Yhteyshenkilön nro',
        CONTACT_EMAIL: 'Sähköposti',
        DELETE_BUTTON: 'Poista osoite',
        DELETE_CONFIRM_TEXT: 'Haluatko varmasti poistaa osoitteen "{0}"?',
        BTN_IMPORT: 'Tuo osoitteita',
        BTN_IMPORT_FINISH: 'Ok, ymmärretty',
        BTN_IMPORT_CORRECT_ROWS: 'Tuo rivit oikeilla tiedoilla',
        BTN_IMPORT_DOWNLOAD_ERROR_XLSX: 'Lataa tiedosto, jossa on virherivejä (.xlsx)',
        ERROR_MALFORMED_ROWS_TEXT: 'Joistakin alla olevista riveistä puuttuu tietoja, joten niitä ei voida tuoda',
        ERROR_DUPLICATED_ROWS_TEXT: 'Jotkin alla olevista osoitteista on jo tallennettu, joten niitä ei voida tuoda',
        MISSING: 'puuttuu',
        IMPORT_EXCEL_FILE: 'Tuo Excel-tiedosto',
        DOWNLOAD_EXCEL_TEMPLATE: 'Lataa Excel-malli',
        ADD_NEW_ADDRESS: 'Lisää uusi osoite',
        SAVE_ADDRESS: 'Tallenna osoite',
        ADDRESS_ADDED_TO_ADDRESS_BOOK: 'Osoite lisätty osoitekirjaan',
    },
    PRICE_REQUEST: {
        TOOLTIP: {
            EXPIRES_SOON: 'Voimassaolo päättyy pian',
            CANCELED: 'Hylätty tai peruutettu',
            CAN_NOT_BE_CANCELED: 'Tätä tarjouspyyntöä ei voi peruuttaa, koska sitä käsitellään jo.'
        },
        PRICE_ORDER_NOW: 'Tilaa nyt',
        WE_CAN_OFFER_PRICES: 'Voimme tarjota hintoja',
        NO_PRICES: 'Jos haluat nähdä hinnat, täytä tiedot tuotteista ja osoitteista.',
        NO_AUTOMATIC_PRICES: "Valitettavasti emme löytäneet automaattisia hintoja pyyntöösi. Operaattori tarkastaa sen lähettämisen jälkeen ja ilmoittaa, mitä voimme tarjota.",
        CREATE_SHIPMENT_ALERT_HEADING: 'Luo uusi tilaus',
        CREATE_SHIPMENT_ALERT: 'Tilauksen tekemistä varten järjestelmä avaa uuden lähetyslomakkeen, joka on esitäytetty hyväksytyn tarjouspyynnön tiedoilla.\n {0} Huomaa, että hinta on voimassa vain samoille tiedoille kuin tarjouspyynnössä.{1} Kun tilaat hyväksytystä tarjouksesta, järjestelmä ei laske automaattisesti uutta hintaa, jos tiedot ovat muuttuneet, tätä varten sinun on otettava yhteyttä logistiikka-asiantuntijaamme.',
        CANCELED: 'Tarjouspyyntö on peruutettu',
        REJECTED: 'Tarjouspyyntö on hylätty',
        TITLE_ACCEPTED: 'Hienoa, hyväksyit hinnan {0}€!',
        TITLE_ACCEPTED_VALID_UNTIL: 'Huomaa, että tämä hinta on voimassa {0} saakka.',
        TITLE_ACCEPTED_TEXT: 'Voit nyt lisätä uuden lähetyksen samoilla tiedoilla kuin tässä tarjouspyynnössä. ',
        TITLE_EXPIRED: 'Tämä tarjous on umpeutunut!',
        HEADER_REJECTED_TITLE_0: 'Tämä tarjouspyyntö on peruutettu!',
        HEADER_REJECTED_TITLE_1: 'Ikävää, että olet hylännyt tarjouksemme: (',
        HEADER_REJECTED_TITLE_1_SMALL: 'Mutta ei se mitään, ymmärrämme.',
        HEADER_REJECTED_TITLE_1_1_SMALL: 'Jos haluat pyytää toista hintaa, aloita \n uusi tarjouspyyntö. Tarjoamme sinulle mielellämme parhaan mahdollisen hinnan!',
        PLEASE_START_A_NEW_PRICE_REQUEST: 'Aloita uusi tarjouspyyntö tai kopioi tämä tarjouspyyntö saadaksesi uudet ja päivitetyt \n-hinnat toimitusta varten.',
        HEADER_EXPIRED_OFFERS: 'Tarjous sisälsi seuraavat hinnat, et valinnut sinulle sopivaa hintaa: ',
        HEADER_EXPIRED_OFFER: 'Tarjous sisälsi valitsemasi hinnan: ',
        OPERATOR_COMMENT: 'Operaattorin kommentti: {0}',
        ADD_NEW_SHIPMENT: 'Luo tilaus',
        ALREADY_GENERATED_SHIPMENTS: 'Olet jo aloittanut toimitukset tähän tarjoukseen liittyen: ',
        INSURANCE: 'Vakuutus',
        ITEMS_VALUE: 'Tuotteen (tuotteiden) arvo',
        CONDITION: 'Ehto',
        PRICE: 'Hinta',
        SAVE_AS_DRAFT_SUCCESS: 'Tarjouspyyntö on tallennettu luonnoksena',
        ADD_COMMENTS_REGARDING_THE_PRICE_REQUEST: 'Lisää tarjouspyyntöä koskevia kommentteja',
        ADD_A_COMMENT: 'Lisää kommentti',
        ADD_AN_E_MAIL_WHERE_WE_WILL_SEND_THE_OFFER: 'Lisää sähköpostiosoite, johon haluat, että lähetämme tarjouksen',
        IM_LOOKING_FOR_PRICES_FOR_30_DAYS: "Jäädytän valitun hinnan 30 päiväksi",
        IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS: "Etsin hintoja säännöllisille lähetyksille",
        IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS_TOOLTIP: "Jos lähetät tällaisia lähetyksiä säännöllisesti, voimme todennäköisesti tarjota paremman hinnan. Jos näin on, täytä alla oleva lomake, jotta voimme ottaa tämän huomioon.",
        PLEASE_ADD_THE_NUMBER_OF_TIMES_AND_PERIOD: 'Lisää kertojen lukumäärä ja aikajakso',
        SEND_PRICE_REQUEST: 'Lähetä tarjouspyyntö',
        ADD_ITEMS_TO_YOUR_PRICE_REQUEST: 'Lisää tuotteita tarjousyyntöösi',
        OFFERED_PRICES: '{0} hinnat',
        OFFERED_PRICE: '{0} hinta',
        FILTER: {
            NUMBER: 'Tarjouspyynnön numero'
        },
        SIDEBAR: {
            DETAILS: 'Tarjouspyynnön tiedot',
            INSURANCE: 'Vakuutus',
            ITEMS_VALUE: 'Tuotteen (tuotteiden) arvo',
            CONDITION: 'Ehto',
            DESCRIPTION: 'Kuvaus',
            REGULAR_SHIPMENTS: 'Säännölliset lähetykset',
            REGULARITY: 'Säännöllisyys',
            STATUSES: 'Tarjouspyynnön tila',
            ACTIONS: 'Kopioi ja peruuta tarjouspyyntö',
            TIMES_PER: 'kertaa per',
        },
        NUMBER: 'Numero',
        PICK_UP_DATE: 'Noutopäivämäärä',
        DELIVERY_DATE: 'Toimituspäivämäärä',
        VALID_UNTIL_DATE: 'Voimassa',
        HEADER_TITLE_1: 'Ahkeroimme löytääksemme sinulle parhaimmat ratkaisut ja hinnat!',
        HEADER_TITLE_1_SMALL: 'Ilmoitamme sinulle täällä ja sähköpostitse, kun meillä on pyyntöön liittyvät hinnat.',
        EMAIL_NOTIFICATIONS: 'Sähköpostiosoite ilmoitusta varten',
        COMMENTS_TITLE: 'Tarjouspyyntöön liittyvät kommentit',
        CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_PRICE_REQUEST: 'Napsauta yhtä tai useampaa tuotetta yllä aloittaaksesi niiden lisäämisen tarjouspyyntöösi',
        TIMES: 'kertaa',
        PER: 'per',
        SELECT_PERIOD: 'Valitse aikajakso',
        EMAIL_WHERE_WE_WILL_SEND_THE_OFFER: 'Sähköpostiosoite, johon lähetämme tarjouksen',
        I_WANT_TO_INSURE_MY_ITEMS: 'Haluan vakuuttaa tuotteeni',
        TOTAL_VALUE_OF_ITEMS: 'Tuotteiden kokonaisarvo',
        PLEASE_ADD_THE_DESCRIPTION_OF_YOUR_ITEMS: 'Lisää kuvaus tuotteistasi',
        TIMES_AND_PERIOD: 'Kerrat ja aikajakso',
        CONFIRM_PRICE_REQUEST: 'Vahvista tarjouspyyntö',
        CANCEL_PRICE_REQUEST_BTN: 'Kyllä, peruuta tarjouspyyntö',
        CANCEL_PRICE_REQUEST: 'Peruuta tarjouspyyntö',
        CANCEL_CONFIRMATION: 'Haluatko varmasti peruuttaa tarjouspyynnön "PR-{0}"?',
        CANCEL_SUCCESS: 'Tarjouspyyntösi on peruutettu onnistuneesti',
        DAYS_DELIVERY: 'päivän toimitus',
        WORKING_DAY_DELIVERY: 'arkipäivä',
        WORKING_DAYS_DELIVERY: 'arkipäivää',
        ESTIMATED_DELIVERY: 'Arvioitu toimitus',
        SAME_DAY_DELIVERY: 'Saman päivän toimitus',
        ON_TIME_DELIVERY: 'Toimitus ajallaan',
        THIS_OPTION_IS_CHOSEN: 'Tämä vaihtoehto on valittu',
        THIS_OPTION_UNSELECT: 'Poista tämän vaihtoehdon valinta',
        CHOOSE_THIS_OPTION: 'Valitse tämä vaihtoehto',
        ADD_AN_EMAIL: 'Lisää tähän sähköpostiosoite tai useampi sähköpostiosoite pilkulla erotettuna. Lähetämme tarjouksen näihin sähköposteihin, kun se on valmis',
        PRICE_REQUEST_VALID_UNTIL: 'Tarjouspyynnön viimeinen voimassaolo',
        APPROVE_OFFER: 'Hyväksy tarjous',
        APPROVE_OFFER_ORDER_NOW: 'Vahvista ja tilaa nyt',
        APPROVE_SUCCESS: 'Tarjous hyväksyttiin onnistuneesti',
        REJECT_THIS_OFFER: 'Hylkää tämä tarjous',
        REJECT_SUCCESS: 'Tarjous hylättiin onnistuneesti',
        PRICE_TOO_HIGH: 'Hinta liian korkea',
        SHIPMENT_POSTPONED: 'Lähetystä lykätty',
        DELIVERY_NOT_NEEDED: 'Toimitusta ei tarvita',
        ASKED_AS_REFERENCE_PRICE: 'Kysytty viitehintana',
        OTHER: 'Muu',
        OFFER_REJECTION_DIALOG_TITLE: 'Kerro meille, miksi hylkäsit tarjouspyynnön?',
        YOUR_REASON_FOR_REJECTION: 'Hylkäämisen syy',
        SEND_YOUR_ANSWER: 'Lähetä vastauksesi',
        PLEASE_COMMENT_YOUR_CHOICE: 'Kommentoi valintaasi',
        REASON_FOR_REJECTION_HAS_TO_BE_SPECIFIED: 'Hylkäämisen syy',
        OFFER_APPROVAL_DIALOG_TITLE: 'Haluatko varmasti hyväksyä hinnan {0}€?',
        OFFER_APPROVAL_DIALOG_TEXT: 'Voit luoda lähetyksen tämän tarjouksen perusteella, kun olet vahvistanut hinnan.',
        PICK_ONE_PRICE_ERROR: 'Valitse hinta, jonka haluat hyväksyä tälle tarjouspyynnölle',
        COMMENT_RELATED_TO_PRICE: 'Hintaan liittyvä kommentti'
    },
    TEMPLATE: {
        TEMPLATE_NAME: 'Mallin nimi',
        TEMPLATES: 'Mallit',
        TEMPLATE: 'Malli',
        TEMPLATES_ADD: 'Lisää uusi malli',
        SEARCH_BY_ALL_TEMPLATES_TEXT_FIELDS: 'Haku mallin nimen tai osoitetietojen perusteella',
        DELETE_BUTTON: 'Poista',
        DELETE_CONFIRM_TEXT: 'Haluatko varmasti poistaa mallin "{0}"?',
        DELETE_SUCCESS: 'Malli poistettiin',
        DELETE_ERROR: 'Mallin poistossa oli ongelmia',
        ADD_ITEMS_TO_YOUR_TEMPLATE: 'Muokkaa mallin tuotteita',
        SAVE_TEMPLATE: 'Tallenna muutokset',
        ADD_TEMPLATE_NAME: 'Lisää mallin nimi',
        CHANGE_TEMPLATE_NAME: 'Muokkaa mallin nimeä',
        TEMPLATE_NAME_LABEL: 'Mallin nimi',
        TEMPLATE_NAME_TOOLTIP: 'Mallin nimi näytetään, kun luot uuden lähetyksen mallin pohjalta. Luo mieleenpainuva nimi, joka auttaa sinua valitsemaan oikean mallin.',
        SAVE_SUCCESS: 'Malli on tallennettu',
        ADDRESSES: {
            EDIT_ROUTE_ADDRESSES: 'Muokkaa mallin osoitteita'
        },
        TEMPLATE_CREATED: 'Malli luotu {0}',
        SEARCH_FOR_A_TEMPLATE_TITLE: 'Hae mallia: ',
    },
    SHIPMENT: {
        STAR_TOOLTIP:'Seuraa lähetystä listalla',
        PICK_ONE_PRICE_ERROR: 'Valitse yksi hinta hyväksyntää varten',
        SEND: 'Lähetä',
        SEND_MESSAGE: 'Lähetä viesti',
        HAS_FILES: 'Lähetys sisältää tiedostoja',
        COMMENTS: 'Viestit',
        HAS_COMMENT: 'Sisältää viestejä',
        NEW_COMMENT: '1 uusi viesti',
        NEW_COMMENTS: '{0} uusia viestejä',
        NO_NEW_COMMENTS: 'Ei uusia viestejä',
        ADD_A_COMMENT: 'Lisää viesti',
        CLEAR_THE_FIELD: 'Tyhjennä kenttä',
        SHIPMENT_TYPE: 'Lähetä/vastaanota tuotteita',
        TYPE: {
            SENDER: 'Lähetän tuotteita',
            RECEIVER: 'Vastaanotan tuotteita'
        },
        YOU_HAVE_UNSAVED_CHANGES: 'Sinulla on tallentamattomia muutoksia.',
        DISCARD_SHIPMENT: 'Hylkää lähetys',
        TITLE_ADD_NEW_SHIPMENT_FROM_TITLE: 'Lisää uusi lähetys mallista',
        BTN_ADD_SHIPMENT: 'Lisää lähetys',
        FROM_TEMPLATE_DESCRIPTION: 'Voit luoda ja hallita malleja avaamalla ”Mallit” otsikon käyttäjävalikosta.',
        BASED_ON_SHIPMENT: 'Perustuu lähetyksen {0}. ',
        SHIPMENT_CREATED: 'Lähetys luotu {0}',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Kuljetus perävaununostimella varustetulla kuorma-autolla',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK_DELIVERY: 'Toimitus perävaununostimella varustetulla kuorma-autolla',
        I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK_TOOLTIP: 'Kuorma-auto, jonka takaosaan on pysyvästi asennettu mekaaninen laite. Se on suunniteltu tavaroiden lastaamiseen maanpinnan tasolta tai lastauslaiturilta ajoneuvon alustan tasolle tai päinvastoin.',
        SAVE_SHIPMENT_AS_A_TEMPLATE: 'Tallenna lähetys {0} mallina',
        THIS_SHIPMENT_IS_NOT_SAVED_AS_TEMPLATE: 'Tätä lähetystä ei ole tallennettu mallina',
        THIS_SHIPMENT_IS_SAVED_AS_TEMPLATE: 'Tämä lähetys perustuu malliin',
        REMOVE_TEMPLATE_CONFIRM_TEXT: 'Haluatko varmasti poistaa "{0}" malleista?',
        SAVE_THIS_SHIPMENT_AS_TEMPLATE_BUTTON: 'Tallenna tämä lähetys mallina',
        CANCEL_CONFIRMATION_DIALOG_TEXT: 'Haluatko varmasti peruuttaa? Lähetysluonnoksen muutoksia ei tallenneta.',
        REMOVE_TEMPLATE_BUTTON: 'Poista malli',
        REMOVE_FROM_TEMPLATES: 'Mallien hallinta',
        NAME_YOUR_TEMPLATE: 'Nimeä mallisi',
        MANAGE_TEMPLATES: 'Mallien hallinta',
        SAVE_TEMPLATE: 'Tallenna malli',
        TEMPLATE_NAME: 'Mallin nimi',
        NOTIFICATIONS_FOR_THIS_SHIPMENT: 'Tähän lähetykseen liittyvät ilmoitukset',
        SEND_SHIPMENT: 'Lähetä lähetys',
        SAVE_AS_DRAFT: 'Tallenna luonnoksena',
        SAVE_AS_DRAFT_SUCCESS: 'Lähetys on tallennettu luonnoksena',
        DELETE_DRAFT: 'Poista luonnos',
        DELETE_DRAFT_CONFIRMATION: 'Haluatko varmasti poistaa lähetysluonnoksen numero {0}?',
        DELETE_DRAFT_SUCCESS: 'Lähetysluonnos poistettu onnistuneesti',
        SHIPMENTS: 'Lähetykset',
        SHIPMENT: 'Lähetys',
        SHIPMENT_NR: 'Lähetysnro',
        REF_NR: 'Viitenro',
        REF_MISSING: 'Viite puuttuu',
        CARRIER_MISSING: 'Kuljetusliike puuttuu',
        PICKUP_MISSING: 'Nouto-osoite puuttuu',
        DELIVERY_MISSING: 'Toimitusosoite puuttuu',
        DATE_MISSING: 'Päivämäärä puuttuu',
        ADD_REFERENCE_NUMBER: 'Lisää viitenumero',
        YOUR_SHIPMENTS_PRICE: "Lähetyksen hinta",
        YOUR_SHIPMENTS_PRICE_DESC: 'Näytetyt hinnat ovat suuntaa-antavia ja voivat muuttua, jos todelliset tuotteet eivät vastaa syötettyjä arvoja',
        NO_PRICES: 'Jos haluat nähdä lähetyksen hinnat, täytä tiedot tuotteista ja osoitteista.',
        NO_AUTOMATIC_PRICES: "Valitettavasti emme tue automaattisia hintoja lähetyksellesi. Operaattori tarkastaa sen lähettämisen jälkeen ja ilmoittaa, mitä voimme tarjota.",
        AUTOMATIC_PRICES_IN_PROGRESS: 'Pyrimme löytämään parhaat hinnat valitsemillesi tuotteille ja reiteille!',
        REFERENCE_NUMBER: 'Viitenumero',
        REFERENCE_NUMBER_TOOLTIP: 'Tämä numero tulee laskulle lähetyksen viitteeksi.',
        NOTIFY_ME_ABOUT_THIS_SHIPMENT: 'Ilmoita minulle tästä lähetyksestä',
        SAVE_THIS_SHIPMENT_AS_TEMPLATE: 'Tallenna tämä lähetys mallina',
        SAVE_THIS_SHIPMENT_AS_TEMPLATE_TOOLTIP: 'Mahdollisuus tallentaa lähetys mallina, jotta samoja tietoja voidaan käyttää tulevaisuudessa uutta lähetystä tehtäessä.',
        EARLIEST_PICKUP_DATE: 'Aikaisin noutopäivämäärä',
        LATEST_PICKUP_DATE: 'Viimeisin noutopäivämäärä',
        EARLIEST_DELIVERY_DATE: 'Aikaisin toimituspäivämäärä',
        LATEST_DELIVERY_DATE: 'Viimeisin toimituspäivämäärä',
        FIXED_PICKUP_DATE: 'Kiinteä noutopäivämäärä',
        FIXED_DELIVERY_DATE: 'Kiinteä toimituspäivämäärä',
        EARLIEST_PICKUP_TIME: 'Aikaisin noutoaika',
        LATEST_PICKUP_TIME: 'Viimeisin noutoaika',
        EARLIEST_DELIVERY_TIME: 'Aikaisin toimitusaika',
        LATEST_DELIVERY_TIME: 'Viimeisin toimitusaika',
        FIXED_PICKUP_TIME: 'Kiinteä noutoaika',
        FIXED_DELIVERY_TIME: 'Kiinteä noutoaika',
        PLEASE_CHECK_YOUR_SHIPMENT_INFORMATION_BEFORE_CONFIRMING: 'Tarkista lähetystietosi ennen vahvistamista',
        SIDEBAR: {
            ACTIONS: 'Kopioi lähetys',
        },
        DATES: {
            ADD_PICKUP_DELIVERY_DATES: "Lisää nouto- ja toimituspäivämäärät",
            PICKUP_DATES: "Noutopäivämäärät",
            DELIVERY_DATES: "Toimituspäivämäärät",
            PICK_PICKUP_DATES: "Lisää aikaisimmat ja viimeisimmät noutopäivämäärät",
            PICK_DELIVERY_DATES: "Lisää aikaisimmat ja viimeisimmät toimituspäivämäärät",
            PICK_PICKUP_FIXED_DATE: "Lisää kiinteä noutopäivämäärä",
            PICK_DELIVERY_FIXED_DATE: "Lisää kiinteä toimituspäivämäärä"
        },
        NAME_THIS_SHIPMENT_TEMPLATE: 'Nimeä tämä lähetysmalli',
        ADDRESSES: {
            ADD_ADDRESS: 'Lisää osoite',
            UPDATE_ADDRESS: 'Päivitä osoite',
            ADD_ROUTE_ADDRESSES: 'Lisää reittiosoitteet',
            REQUIRED_FIELDS_ARE_MARKED_WITH: 'Pakolliset kentät on merkitty',
            WHERE_TO_PICK_UP_FROM: 'Mistä noudetaan?',
            WHERE_TO_DELIVER: 'Minne toimitetaan?',
            RECENTLY_USED_ADDRESSES: 'Viime aikoina käytetyt osoitteet',
            CHOOSE_FROM_ADDRESS_BOOK: 'Valitse osoitekirjasta',
            ADD_MANUALLY: 'Lisää manuaalisesti',
            CLEAR_ALL_FIELDS: 'Tyhjennä kaikki kentät',
            SAVE_THIS_ADDRESS: 'Tallenna tämä osoite: ',
            NAME_THIS_ADDRESS: 'Nimeä tämä osoite',
            SENDER_AND_DELIVERY_ADDRESSES: 'Lähettäjän ja toimituksen osoitteet',
            TYPE_HERE_THE_ADDRESS_AND_SELECT_IT_FROM_GOOGLE_RESULTS_OR_FROM_ADDRESS_BOOK: 'Kirjoita tähän osoite ja valitse se Google-tuloksista tai osoitekirjasta',
            COUNTRY: 'Maa',
            CITY: 'Kaupunki',
            POSTCODE: 'Postinumero',
            COMPANY_NAME: 'Yrityksen nimi',
            CONTACT_PERSON: 'Yhteyshenkilö',
            CONTACT_NUMBER: 'Yhteysnumero',
            EMAIL: 'Sähköposti',
            REFERENCE: 'Viite',
            LOADING_REFERENCE: 'Noutoviite',
            LOADING_REFERENCE_TOOLTIP: 'Noutopaikkaan/rakennukseen jne. liittyvä numero.',
            UNLOADING_REFERENCE: 'Purkausviite',
            UNLOADING_REFERENCE_TOOLTIP: 'Toimituksen lastauspaikan/rakennuksen jne. numero',
            ADD_AN_ALTERNATIVE_ADDRESS: 'Lisää vaihtoehtoinen osoite',
            ADDRESS_DETAILS: 'Osoitteen tiedot',
            ADDRESS: 'Osoite',
            ADD_MORE_INFORMATION: 'Lisää lisätietoja',
            CLOSE_MORE_INFORMATION: 'Sulje lisätiedot',
            SENDER: {
                INFORMATION: 'Lähettäjän tiedot',
                ADDRESS: 'Lähettäjän osoite',
                CONTACT_PERSON: 'Lähettäjän yhteyshenkilö',
                ADDRESS_DETAILS: 'Lähettäjän osoitetiedot',
                ADDRESS_DETAILS_TOOLTIP: 'Osoitteeseen liittyvät tiedot, joilla osoitetaan tietty paikka tavaroiden noutoa varten (rakennuksen puoli, ovi, terminaali jne.)',
                ALTERNATIVE_ADDRESS: 'Vaihtoehtoinen lähettäjän osoite',
                ALTERNATIVE_CONTACT_PERSON: 'Vaihtoehtoinen lähettäjän yhteyshenkilö',
                ALTERNATIVE_ADDRESS_DETAILS: 'Vaihtoehtoiset lähettäjän osoitetiedot',
                ADD_AN_ALTERNATIVE_ADDRESS: 'Lisää vaihtoehtoinen lähettäjän osoite',
            },
            RECEIVER: {
                INFORMATION: 'Vastaanottajan tiedot',
                ADDRESS: 'Vastaanottajan osoite',
                CONTACT_PERSON: 'Vastaanottajan yhteyshenkilö',
                ADDRESS_DETAILS: 'Vastaanottajan osoitetiedot',
                ADDRESS_DETAILS_TOOLTIP: 'Osoitteeseen liittyvät tiedot, joilla osoitetaan tietty tavaroiden toimituspaikka (rakennuksen puoli, ovi, terminaali jne.)',
                ALTERNATIVE_ADDRESS: 'Vaihtoehtoinen vastaanottajan osoite',
                ALTERNATIVE_CONTACT_PERSON: 'Vaihtoehtoinen vastaanottajan yhteyshenkilö',
                ALTERNATIVE_ADDRESS_DETAILS: 'Vaihtoehtoisen vastaanottajan osoitetiedot',
                ADD_AN_ALTERNATIVE_ADDRESS: 'Lisää vaihtoehtoisen vastaanottajan osoite',
            },
        },
        ADD_ITEMS_TO_YOUR_SHIPMENT: 'Lisää tuotteita lähetykseesi',
        CLICK_ON_THE_ITEM_YOU_WANT_TO_ADD: 'Napsauta tuotetta, jonka haluat lisätä',
        ITEMS_ADDED: 'Tuotteita lisätty',
        CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_SHIPMENT: 'Napsauta yhtä tai useampaa tuotetta yläpuolella aloittaaksesi niiden lisäämisen lähetykseesi',
        I_NEED_PICKUP_WITH_A_TAIL_LIFT_TRUCK: 'Tarvitsen noudon takalaitanostimella varustettuun kuorma-autoon',
        I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK: 'Tarvitsen toimituksen perälautanostimella varustetulla kuorma-autolla',
        ADD_A_COMMENT_REGARDING_THE_ITEMS_ADDED: 'Lisää kuljetukseen liittyvä kommentti',
        DOCUMENTS: {
            DELETE_TOOLTIP: 'Tätä asiakirjaa ei voida poistaa, koska se on operaattorin luoma',
        },
        CMR_NOT_AVAILABLE: 'Rahtikirjaa ei voida vielä ladata, koska kuljetusyhtiötä ei ole valittu',
        POD_NOT_AVAILABLE: 'Tätä asiakirjaa ei voida vielä ladata, koska POD-tiedostoja puuttuu',
        ITEM: {
            SELECT_TYPE: 'Valitse tyyppi',
            TOTAL: 'Yhteensä',
            DETAILS: {
                PC: 'kpl',
                TYPE: 'Tyyppi',
                LENGTH: 'Pituus',
                LENGTH_TOOLTIP: 'Pituus on kiinteä valitulle tuotetyypille',
                WIDTH: 'Leveys',
                WIDTH_TOOLTIP: 'Leveys on kiinteä valitulle tuotetyypille',
                HEIGHT: 'Korkeus',
                VOLUME: 'Tilavuus',
                WEIGHT_PC: 'Paino/kpl',
                TOTAL_WEIGHT: 'Kokonaispaino',
                LDM: 'LDM',
                LDM_TOOLTIP: 'Kuormametri (perävaunun pituus juoksumetreinä täydessä leveydessä ja korkeudessa). Tämä lasketaan automaattisesti pituuden, leveyden ja määrän perusteella, mutta voit tarvittaessa kirjoittaa eri arvon.',
                REFERENCE_NUMBER: 'Viitenumero',
                PRODUCT_DESCRIPTION: 'Tuotekuvaus',
                UN_NO: 'UN-nro',
                UN_NO_TOOLTIP: 'Numero, joka yksilöi vaaralliset aineet, vaaralliset aineet ja esineet',
                UN: 'UN',
                UN_CODE: 'UN-koodi',
                PG: 'PG',
                PG_TOOLTIP: 'Vaaralliset aineet luokitellaan kolmeen pakkausryhmään niiden aiheuttaman vaaran mukaan',
                CLASS: 'Luokka',
                CLASS_TOOLTIP: 'Vaarallisten aineiden luokitusnumerot',
                DANGEROUS_GOODS: 'Vaaralliset tuotteet',
                COLD_FROZEN: 'Kylmä/pakaste',
                STACKABLE: 'Pinottavissa',
                DELICATE: 'Särkyvää',
                MIN_TEMP: 'Min. lämpötila',
                MIN_TEMP_TOOLTIP: 'Kuljetuksen aikana edellytetty tuotteiden vähimmäislämpötila',
                MAX_TEMP: 'Maks. lämpötila',
                MAX_TEMP_TOOLTIP: 'Kuljetuksen aikana edellytetty tuotteiden enimmäislämpötila',
                PROPER_SHIPPING_NAME: 'Asianmukainen laivausnimi',
                PROPER_SHIPPING_NAME_TOOLTIP: 'Vaarallisten aineiden vaarallisia ominaisuuksia ja koostumusta kuvaava nimi',
                PROPER_SHIPPING_NAME_PLACEHOLDER: 'Mustaruuti, tiivistetty tai aseruuti',
                PACKAGE_INFO: 'Merkitse pisimmän sivun mitta kenttään ”Pituus”, kun pakkaus on pinottavissa.'
            },
            TYPE: {
                PALLET: 'Lava',
                PACKAGE: 'Paketti',
                TRUCK: 'Kuorma',
                OTHER: 'LVM',
                CONTAINER: 'Kontti',
                MACHINE:'Kone'
            },
            SUBTYPE: {
                EUR_PALLET: 'EURO-lava',
                FIN_PALLET: 'FIN-lava',
                PALLET: 'Lava',
                PACKAGE: 'Paketti',
                BOX: 'Laatikko',
                FULL_TRUCK_LOAD: 'Täysi kuorma',
                LONG_PALLET: 'Pitkä lava',
                HALF_PALLET: 'Puolikas lava',
                LDM: 'LVM',
                CONTAINER_20_DC: '20’DC',
                CONTAINER_40_DC: '40’DC',
                CONTAINER_40_HC: '40’HC',
                CONTAINER_OTHER: 'Muu',
                MACHINE:'Kone'
            },
            CLASS: {
                EXPLOSIVES: 'Räjähteitä',
                GASES: 'Kaasuja',
                FLAMMABLE_LIQUIDS: 'Syttyvät nesteet',
                FLAMMABLE_SOLIDS: 'Syttyvät kiinteät aineet',
                OXIDIZERS: 'Hapettimet',
                TOXIC_SUBSTANCES: 'Myrkylliset aineet',
                RADIOACTIVE: 'Radioaktiivinen',
                CORROSIVES: 'Syövyttävät aineet',
                MISCELLANOUS: 'Sekalaista',
            },
            PG: {
                PG1: 'Pakkausryhmä I: suuri vaara',
                PG2: 'Pakkausryhmä II: keskivaarallinen',
                PG3: 'Pakkausryhmä III: vähäinen vaara',
            },
        },
        DELETE_DOCUMENT: 'Poista asiakirja',
        DELETE_CONFIRM_TEXT: 'Haluatko varmasti poistaa asiakirjan {0}"?',
        ADD_DOCUMENT_MODAL_TITLE: 'Lisää uusi asiakirja',
        ADD_DOCUMENT_BUTTON: 'Lisää asiakirja',
        CUSTOMER_CONFIRMED: 'Asiakas vahvistanut: {0}',
        PICKUP_ON: 'Noutoajankohta: {0}',
        PICKUP_FROM: 'Noutokohde: {0}',
        PICKUP_BETWEEN: 'Noutoaika välillä: {0} ja {1}',
        DELIVERY_ON: 'Toimitusajankohta: {0}',
        DELIVERY_FROM: 'Toimitus alkaen: {0}',
        DELIVERY_BETWEEN: 'Toimitus välillä: {0} ja {1}',
        IMPORT_MODAL_TITLE: 'Tuo lähetyksiä Excel-tiedostosta',
        IMPORT_MODAL_TITLE_CORRECT_ROWS: '{0} lähetystä tuotu onnistuneesti',
        IMPORT_MODAL_TITLE_INCORRECT_ROWS: 'Lähetyksiä ei tuotu Excel-tiedostosta',
        IMPORT_MODAL_TITLE_SMALL: 'Vain yksi Excel-tiedosto voidaan tuoda kerralla.',
        BTN_IMPORT: 'Tuo lähetykset',
        BTN_IMPORT_FINISH: 'Ok, ymmärretty.',
        BTN_IMPORT_CORRECT_ROWS: 'Ok, ymmärretty. Ladataan uusi tiedosto',
        BTN_IMPORT_DOWNLOAD_ERROR_XLSX: 'Lataa tiedosto, jossa on virherivejä (.xlsx)',
        ERROR_MALFORMED_ROWS_TEXT: 'Joillakin alla olevilla riveillä on validointivirheitä, joten niitä ei voida tuoda',
        ERROR_DUPLICATED_ROWS_TEXT: 'Jotkin alla näkyvät lähetykset on jo tallennettu, joten niitä ei voida tuoda',
        EXCEL_ROW_NUMBER: 'Excel-rivin numero',
        EXCEL_ROW_MISSING_WRONG_INFO: 'Puuttuvat tai väärät tiedot',
        EXCEL_ROW_EXISTS: 'Lähetys, joka on olemassa samoilla tiedoilla',
        EXCEL_IMPORT_ERRORS: {
            SENDER_REQUIRED: 'Lähettäjä puuttuu',
            SENDER_ADDRESS_REQUIRED: 'Lähettäjän osoite puuttuu',
            SENDER_CITY_REQUIRED: 'Lähettäjän kaupunki puuttuu',
            SENDER_POST_CODE_REQUIRED: 'Lähettäjän postinumero puuttuu',
            SENDER_COUNTRY_REQUIRED: 'Lähettäjän maa puuttuu',
            SENDER_CONTACT_PERSON_REQUIRED: 'Lähettäjän yhteyshenkilön nimi puuttuu',
            SENDER_CONTACT_PHONE_REQUIRED: 'Lähettäjän yhteyshenkilön puhelin puuttuu',
            RECEIVER_REQUIRED: 'Vastaanottaja puuttuu',
            RECEIVER_ADDRESS_REQUIRED: 'Vastaanottajan osoite puuttuu',
            RECEIVER_CITY_REQUIRED: 'Vastaanottajan kaupunki puuttuu',
            RECEIVER_POST_CODE_REQUIRED: 'Vastaanottajan postinumero puuttuu',
            RECEIVER_COUNTRY_REQUIRED: 'Vastaanottajan maa puuttuu',
            RECEIVER_CONTACT_PERSON_REQUIRED: 'Vastaanottajan yhteyshenkilön nimi puuttuu',
            RECEIVER_CONTACT_PHONE_REQUIRED: 'Vastaanottajan yhteyshenkilön puhelin puuttuu',
            PICKUP_DATE_ERROR: 'Noutopäivämäärä puuttuu',
            DELIVERY_DATE_ERROR: 'Toimituspäivä puuttuu',
            DELIVERY_DATE_IS_EARLIER_THAN_PICKUP_ERROR: 'Tämä päivämäärä on aikaisempi kuin korjattu päivämäärä',
            QUANTITY_ERROR: 'Määrä puuttuu',
            PACKAGE_ERROR: 'Paketin tyyppi puuttuu',
            WEIGHT_ERROR: 'Paino puuttuu',
            WIDTH_ERROR: 'Leveys puuttuu',
            LENGTH_ERROR: 'Pituus puuttuu',
            HEIGHT_ERROR: 'Korkeus puuttuu',
            PICKUP_DATE_IN_PAST: 'Noutopäivä on menneisyydessä',
            LDM_ERROR: 'LDM puuttuu',
        },
        BTN_DOWNLOAD_XLSX_TEMPLATE: 'Lataa malli (.xlsx)',
    },
    SWITCH: {
        YES: 'KYLLÄ',
        NO: 'EI',
    },
    VALIDATION: {
        IS_REQUIRED_TEXT: 'Pakolliset kentät on merkitty *',
        INVALID_VALUE: 'Virheellinen arvo',
        THIS_FIELD_IS_REQUIRED: 'Tämä kenttä on pakollinen',
        YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ITEM: 'Sinun on valittava vähintään yksi kohde',
        AT_LEAST_ONE_ITEM_NEEDS_TO_BE_ADDED_IN_THE_SHIPMENT: 'Valitse vähintään 1 tuote, jotta lähetys olisi täydellinen',
        SOME_REQUIRED_FIELDS_ARE_NOT_FILLED: 'Joitakin pakollisia kenttiä ei ole täytetty',
        NEW_SHIPMENT_VALIDATION_ERROR: 'Lähetystä ei voida tallentaa, koska lähetyksestä puuttuu tietoja.',
        INVALID_PHONE: 'Virheellinen puhelinnumero',
        INVALID_EMAIL: 'Virheellinen sähköpostiosoite',
        SELECTED_DATE_IS_WRONG: 'Valittu päivämäärä on väärä',
        SELECTED_EARLIEST_DATE_IS_WRONG: 'Tarkista viimeisin päivämäärä',
        SELECTED_ARRIVAL_DATE_IS_WRONG: 'Tarkista aikaisin toimituspäivä',
        TEMPLATE_NAME: 'Mallin nimen tulee sisältää vähintään 3 merkkiä'
    },
    UNITS: {
        PC: 'kpl'
    },
    COMPANY: {
        OFFICES:"Toimistojen ja varastojen osoitteet",
        IS_PRIMARY_OFFICE: "Oletustoimisto",
        EDIT_OFFICE_ADDRESS: "Syötä toimiston / varaston osoite ja yhteystiedot",
        OFFICE_DELETE_CONFIRMATION:'Oletko varma, että haluat poistaa osoitteen?',
        OFFICE_DELETE_SUCCESS:'Toimiston tai varaston osoite on poistettu!',
        CLIENT_CODE: "Asiakaskoodi",
        CLIENT_CODE_TOOLTIP: 'E.g You can share it to your suppliers to make orders.',
        ORDER_URL: 'URL',
        ORDER_URL_COPIED: 'Order url copied',
        COMPANY: 'Yhtiö',
        COMPANY_NAME: 'Yrityksen nimi',
        COMPANY_PROFILE: 'Yrityksen profiili',
        COMPANY_DETAILS: 'Yrityksen tiedot',
        EDIT_DETAILS: 'Muokkaa tietoja',
        WORKING_HOURS: 'Työaika',
        VAT_NUMBER: 'ALV-numero',
        REGISTRY_CODE: 'Rekisterikoodi',
        CONTACT_PERSON: 'Yhteyshenkilö',
        EDIT_PERSON: 'Muokkaa henkilöä',
        PHONE_NO: 'Puhelinro',
        EMAIL: 'Sähköposti',
        ADDRESSES: 'Osoitteet',
        OFFICIAL_ADDRESS: 'Yrityksen virallinen osoite',
        EDIT_OFFICIAL_ADDRESS: 'Muokkaa yrityksen virallista osoitetta',
        MAIN_ADDRESS: 'Yrityksen virallinen osoite',
        EDIT_MAIN_ADDRESS: 'Muokkaa yrityksen virallista osoitetta',
        EDIT_INVOICE_ADDRESS: 'Muokkaa laskutusosoitetta',
        INVOICE_CONFIGURATION: 'Laskun kokoonpano',
        INVOICE_COMPANY_DETAILS: 'Laskun yrityksen tiedot',
        INVOICE_CONTACT_PERSON: 'Laskun yhteyshenkilö',
        INVOICE_RECEIVER_EMAIL: 'Laskun vastaanottajan sähköposti',
        INVOICE_TYPE: 'Laskun tyyppi',
        DUE_DATE_DAYS: 'Eräpäivät',
        INVOICE_ADDRESS: 'Laskutusosoite',
        ADDRESS: 'Osoite',
        CITY: 'Kaupunki',
        REGION: 'Alue',
        POSTAL_CODE: 'Postinumero',
        COUNTRY: 'Maa',
        EDIT: 'Muokkaa',
        DETAILED: 'Yksityiskohtainen',
        REGULAR: 'Säännöllinen',
        APPLY_THIS_INFORMATION_TO_MAIN_ADDRESS: 'Sovelletaan tätä tietoa yrityksen pääosoitteeseen',
        APPLY_THIS_INFORMATION_TO_INVOICE_ADDRESS: 'Sovelletaan tätä tietoa laskutusosoitteeseen',
        APPLY_THIS_INFORMATION_TO_INVOICE_DETAILS: 'Sovelletaan tätä tietoa laskun yritystietoihin',
        APPLY_THIS_INFORMATION_TO_INVOICE_CONTACT: 'Sovelletaan nämä tiedot laskun yhteyshenkilöön',
        DAYS: 'päivää',
        EDIT_COMPANY_DETAILS: 'Muokkaa yrityksen tietoja',
        EDIT_INVOICE_COMPANY_DETAILS: 'Muokkaa laskun yritystietoja',
        EDIT_CONTACT_PERSON: 'Muokkaa yhteyshenkilöä',
        EDIT_INVOICE_CONTACT_PERSON: 'Muokkaa laskun yhteyshenkilöä',
        SAVE_CHANGES: 'Tallenna muutokset',
    },
    CLAIMS: "Poikkeamat",
    CLAIM: {
        ADD_NEW_FROM_SHIPMENT: 'Lisää uusi reklamaatio lähetyksestä:',
        ADD_NEW_FROM_SHIPMENT_DESCRIPTION: 'Reklamaation lisäämiseksi sinun täytyy löytää oikea lähetys.',
        SEARCH_FOR_A_SHIPMENT_TITLE: 'Etsi lähetystä numeron perusteella:',
        SEARCH_BY_KEYWORD: "Haku avainsanan mukaan",
        PLEASE_CONFIRM: "Lähetä",
        CONFIRM_TEXT: "Varmista, että kaikki tiedot ovat oikein",
        DOWNLOAD_PDF: "Lataa PDF",
        DAMAGE_COST: "Vaurion kustannus",
        NUMBER: "Numero",
        SAVE_AS_DRAFT_SUCCESS: 'Poikkeama tallennettu luonnoksena',
        DELETE_DRAFT_SUCCESS: 'Poikkeamaluonnos poistettu',
        DELETE_DRAFT_CONFIRMATION: 'Oletko varma, että haluat poistaa korvausvaatimusluonnoksen?',
        COMPANY_INFO_TITLE: 'Yrityksen tiedot',
        GENERAL_INFO: 'Yleiset tiedot',
        SHIPMENT_NR: 'Lähetyksen numero',
        CLAIM_DATE: 'Hakemus jätetty',
        DIRECTION: {
            TITLE: 'Suunta',
            EXPORT: 'Lähtevä',
            IMPORT: 'Tuleva'
        },
        COMPANY: {
            "NAME": "Yhtiön nimi",
            "ADDRESS": "Osoite",
            "CONTACT": "Yhteyshenkilö",
            "EMAIL": "Sähköposti",
            "PHONE": "Puhelin",
            "IBAN": "Pankkitili"
        },
        SIDEBAR: {
            DETAILS: "Vaatimuksen tiedot",
            STATUSES: "Vaatimuksen tila",
        },
        DATE: "Vaatimuksen jättämispäivämäärä",
        SHIPMENT_NUMBER: "Lähetysnumero",
        REASON: "Vaatimuksen perustelu",
        REASON_ERROR: "Vähintään yksi syy on valittava",
        REASONS: {
            "FULLY_LOST": "Koko lähetyksen menetys",
            "FULLY_DAMAGED": "Lähetyksen vaurioituminen kokonaisuudessaan",
            "PARTLY_LOST": "Lähetyksen osittain menetys",
            "PARTLY_DAMAGED": "Lähetyksen osittainen vaurioituminen",
            "WAS_LATE": "Lähetyksen viivästyminen",
        },
        REASON_FULLY_TITLE: "Koko lähetyksen kuvaus",
        REASON_PARTLY_TITLE: "Osittain vaurioituneen tai menetetyn lähetyksen kuvaus",
        SEND_CLAIM: "Lähetä vaatimus",
        DESCRIPTION_TITLE: "Vahingon toteamisajankohta, kuvaus ja vahingon määrä",
        DESCRIPTION: "Vahingon havaitsemisajankohta ja kuvaus",
        COST: "Vahinkojen arvo (€)",
        SHIPMENT_INFO: {
            DESCRIPTION: "Tuotteiden kuvaus",
            QUANTITY: "Pakkausyksiköiden lukumäärä",
            PACKAGE_TYPE: "Pakkaustyyppi",
            WEIGHT: "Bruttopaino (kg)",
            COST: "Lähetyksen arvo (€)"
        },
        FILES_ADDED: {
            attachedCmr: 'Allekirjoitettu rahtikirja',
            attachedInvoices: 'Lähetyksen osto- tai myyntilasku(t)',
            attachedExpenseDocuments: 'Lasku(t) korjauskustannuksista',
            attachedLabels: 'Pakkausluettelo',
            attachedPictures: 'Kuvia vahingoittuneista tavaroista ja pakkauksesta',
            attachedOtherDocuments: 'Muut asiakirjat, jotka osoittavat vahingonkorvauksen määrän',
        },
        STATUS_Draft: 'Luonnos',
        STATUS_Processing: 'Käynnissä',
        STATUS_Accepted: 'Hyväksytty',
        STATUS_Rejected: 'Hylätty',
        STATUS_DECISION: 'Ratkaisu',
        STATUS_DESCRIPTION: 'Ratkaisun kuvaus'
    },
    One_office_required: 'Valitse vähintään yksi sijainti!'
};