import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
  NewShipmentCommentPayload,
  ShipmentCommentsPayload,
} from 'shared/types/shipments/shipments';

import { useShipments } from 'shared/services/shipments';
import { Button, Labeled, ShipmentComment, TextFieldFormik, Typography } from 'components';
import { theme } from 'theme';
import { useUser } from 'shared/hooks/useUser';
import { hasPermission } from 'shared/functions/hasPermission';
import { UserAction, UserActionType } from 'shared/constants/user/userRights';

const useStyles = makeStyles({
  form: {
    marginBottom: theme.spacing(10),

    '& fieldset': {
      marginBottom: theme.spacing(4),
    },

    '& textarea': {
      marginBottom: theme.spacing(4),
      minHeight: theme.spacing(26),
    },
  },
  commentsWrapper: {
    marginTop: theme.spacing(10),
  },
  comment: {
    background: theme.palette.custom.lightCyan,
    marginBottom: theme.spacing(6),
    padding: theme.spacing(4, 4, 4, 5.5),
    fontWeight: 100,
    borderLeft: `solid ${theme.spacing(0.75)}px ${theme.palette.custom.darkCyan}`,
    borderRadius: theme.spacing(0, 2, 2, 0),
    whiteSpace: 'break-spaces',
  },
  reset: {
    userSelect: 'none',
  },
  submit: {
    marginLeft: theme.spacing(6),
  },
  commentBody: {
    marginTop: theme.spacing(1),
  },
  author: {
    fontWeight: 700,
  },
  date: {
    marginLeft: theme.spacing(4),
    color: theme.palette.custom.veryDarkGrayAlt2,
  },
  read: {
    background: 'none',
    fontWeight: 100,
  },
  observer: {
    border: 'solid 1px red',
  },
});

type ShipmentCommentsProps = {
  shipmentId: string;
  comments?: ShipmentCommentsPayload;
  getComments: () => void;
  object: 'Shipment' | 'PriceRequest';
};

const ShipmentComments: FC<ShipmentCommentsProps> = ({
  shipmentId,
  comments,
  getComments,
  object,
}): ReactElement => {
  const { t } = useTranslation();
  const { saveShipmentComment } = useShipments();
  const formRef = useRef<FormikProps<NewShipmentCommentPayload> | null>(null);
  const commentsRef = useRef<HTMLDivElement>(null);

  const [isPending, setIsPending] = useState(false);

  const classes = useStyles();
  const user = useUser();
  type FormValues = {
    comment: string;
    isPublic: boolean;
    operatorRead: string | null;
    customerRead: string | null;
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string().min(1, t('COMMENT_TOO_SHORT')).required(),
  });

  const initialValues: FormValues = {
    comment: '',
    isPublic: true,
    operatorRead: null,
    customerRead: new Date().toISOString(),
  };

  const handleSubmit = async (
    payload: FormValues,
    actions: FormikHelpers<FormValues>,
    id: number,
  ) => {
    setIsPending(true);
    try {
      const result = await saveShipmentComment({ payload, id, object });

      if (result.isSuccessful) {
        actions.resetForm({});
        getComments();
      }
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    if (window.location.hash === '#messages') {
      commentsRef.current?.scrollIntoView();
    }
  }, [comments]);

  const componentUserActon: UserAction =
    object === 'Shipment' ? UserAction.shipments : UserAction.priceRequests;

  return (
    <Box className={classes.commentsWrapper}>
      {hasPermission(user, componentUserActon, UserActionType.ViewOnly, 'shipments') &&
        comments &&
        comments?.length > 0 && (
          <Typography variant="h5" marginBottom={5}>
            {t('SHIPMENT.COMMENTS')}
          </Typography>
        )}
      {hasPermission(user, componentUserActon, UserActionType.Edit, 'shipments') && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions, +shipmentId)}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          innerRef={formRef}
        >
          {({ resetForm, values }) => (
            <Form className={classes.form}>
              <Labeled variant="variant1" text={t('SHIPMENT.ADD_A_COMMENT')}>
                <TextFieldFormik
                  name="comment"
                  variant="variant6"
                  size="small"
                  multiline
                  fullWidth
                  rows={6}
                  value={values.comment || ''}
                />
              </Labeled>
              <Box textAlign="right">
                <Typography
                  link
                  variant="caption"
                  className={classes.reset}
                  onClick={() => resetForm({})}
                >
                  {t('SHIPMENT.CLEAR_THE_FIELD')}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  disabled={isPending}
                  className={classes.submit}
                >
                  {t('SHIPMENT.SEND_MESSAGE')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
      <div ref={commentsRef}>
        {comments &&
          comments.map((comment) => {
            return <ShipmentComment key={comment.id} comment={comment} object={object} />;
          })}
      </div>
    </Box>
  );
};

export default ShipmentComments;
